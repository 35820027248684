import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

@Injectable({
    providedIn: 'root'
})
export class ChatService
{

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onChatSelected = new BehaviorSubject(null);
        this.onContactSelected = new BehaviorSubject(null);
        this.onChatsUpdated = new Subject();
        this.onUserUpdated = new Subject();
        this.onLeftSidenavViewChanged = new Subject();
        this.onRightSidenavViewChanged = new Subject();
    }

    contacts = [
        {
            'id'    : '5725a680b3249760ea21de52',
            'name'  : 'Alice Freeman',
            'avatar': 'assets/images/avatars/alice.jpg',
            'status': 'online',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a680606588342058356d',
            'name'  : 'Arnold',
            'avatar': 'assets/images/avatars/Arnold.jpg',
            'status': 'do-not-disturb',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a68009e20d0a9e9acf2a',
            'name'  : 'Barrera',
            'avatar': 'assets/images/avatars/Barrera.jpg',
            'status': 'do-not-disturb',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            'unread': null
        },
        {
            'id'    : '5725a6809fdd915739187ed5',
            'name'  : 'Blair',
            'avatar': 'assets/images/avatars/Blair.jpg',
            'status': 'offline',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            'unread': 3
        },
        {
            'id'    : '5725a68007920cf75051da64',
            'name'  : 'Boyle',
            'avatar': 'assets/images/avatars/Boyle.jpg',
            'status': 'offline',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a68031fdbb1db2c1af47',
            'name'  : 'Christy',
            'avatar': 'assets/images/avatars/Christy.jpg',
            'status': 'offline',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a680bc670af746c435e2',
            'name'  : 'Copeland',
            'avatar': 'assets/images/avatars/Copeland.jpg',
            'status': 'online',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a680e7eb988a58ddf303',
            'name'  : 'Estes',
            'avatar': 'assets/images/avatars/Estes.jpg',
            'status': 'away',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a680dcb077889f758961',
            'name'  : 'Harper',
            'avatar': 'assets/images/avatars/Harper.jpg',
            'status': 'offline',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a6806acf030f9341e925',
            'name'  : 'Helen',
            'avatar': 'assets/images/avatars/Helen.jpg',
            'status': 'away',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a680ae1ae9a3c960d487',
            'name'  : 'Henderson',
            'avatar': 'assets/images/avatars/Henderson.jpg',
            'status': 'offline',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a680b8d240c011dd224b',
            'name'  : 'Josefina',
            'avatar': 'assets/images/avatars/Josefina.jpg',
            'status': 'online',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a68034cb3968e1f79eac',
            'name'  : 'Katina',
            'avatar': 'assets/images/avatars/Katina.jpg',
            'status': 'away',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a6801146cce777df2a08',
            'name'  : 'Lily',
            'avatar': 'assets/images/avatars/Lily.jpg',
            'status': 'do-not-disturb',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a6808a178bfd034d6ecf',
            'name'  : 'Mai',
            'avatar': 'assets/images/avatars/Mai.jpg',
            'status': 'away',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a680653c265f5c79b5a9',
            'name'  : 'Nancy',
            'avatar': 'assets/images/avatars/Nancy.jpg',
            'status': 'do-not-disturb',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a680bbcec3cc32a8488a',
            'name'  : 'Nora',
            'avatar': 'assets/images/avatars/Nora.jpg',
            'status': 'do-not-disturb',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a6803d87f1b77e17b62b',
            'name'  : 'Odessa',
            'avatar': 'assets/images/avatars/Odessa.jpg',
            'status': 'away',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a680e87cb319bd9bd673',
            'name'  : 'Reyna',
            'avatar': 'assets/images/avatars/Reyna.jpg',
            'status': 'offline',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a6802d10e277a0f35775',
            'name'  : 'Shauna',
            'avatar': 'assets/images/avatars/Shauna.jpg',
            'status': 'online',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            'unread': null
        },
        {
            'id'    : '5725a680aef1e5cf26dd3d1f',
            'name'  : 'Shepard',
            'avatar': 'assets/images/avatars/Shepard.jpg',
            'status': 'online',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a680cd7efa56a45aea5d',
            'name'  : 'Tillman',
            'avatar': 'assets/images/avatars/Tillman.jpg',
            'status': 'do-not-disturb',
            'mood'  : ''
        },
        {
            'id'    : '5725a680fb65c91a82cb35e2',
            'name'  : 'Trevino',
            'avatar': 'assets/images/avatars/Trevino.jpg',
            'status': 'away',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a68018c663044be49cbf',
            'name'  : 'Tyson',
            'avatar': 'assets/images/avatars/Tyson.jpg',
            'status': 'do-not-disturb',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
            'id'    : '5725a6809413bf8a0a5272b1',
            'name'  : 'Velazquez',
            'avatar': 'assets/images/avatars/Velazquez.jpg',
            'status': 'online',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        }
    ];

    chats = [
        {
            'id'    : '1725a680b3249760ea21de52',
            'dialog': [
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                    'time'   : '2017-03-22T08:54:28.299Z'
                },
                {
                    'who'    : '5725a6802d10e277a0f35724',
                    'message': 'I’m having breakfast right now, can’t you wait for 10 minutes?',
                    'time'   : '2017-03-22T08:55:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'We are losing money! Quick!',
                    'time'   : '2017-03-22T09:00:28.299Z'
                },
                {
                    'who'    : '5725a6802d10e277a0f35724',
                    'message': 'It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.',
                    'time'   : '2017-03-22T09:02:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'You are the worst!',
                    'time'   : '2017-03-22T09:05:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'We are losing money! Quick!',
                    'time'   : '2017-03-22T09:15:28.299Z'
                },
                {
                    'who'    : '5725a6802d10e277a0f35724',
                    'message': 'It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.',
                    'time'   : '2017-03-22T09:20:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'You are the worst!',
                    'time'   : '2017-03-22T09:22:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'We are losing money! Quick!',
                    'time'   : '2017-03-22T09:25:28.299Z'
                },
                {
                    'who'    : '5725a6802d10e277a0f35724',
                    'message': 'It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.',
                    'time'   : '2017-03-22T09:27:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'You are the worst!',
                    'time'   : '2017-03-22T09:33:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'We are losing money! Quick!',
                    'time'   : '2017-03-22T09:35:28.299Z'
                },
                {
                    'who'    : '5725a6802d10e277a0f35724',
                    'message': 'It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.',
                    'time'   : '2017-03-22T09:45:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'You are the worst!',
                    'time'   : '2017-03-22T10:00:28.299Z'
                }
            ]
        },
        {
            'id'    : '2725a680b8d240c011dd2243',
            'dialog': [
                {
                    'who'    : '5725a680b8d240c011dd224b',
                    'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                    'time'   : '2017-04-22T01:00:00.299Z'
                },
                {
                    'who'    : '5725a6802d10e277a0f35724',
                    'message': 'I’m having breakfast right now, can’t you wait for 10 minutes?',
                    'time'   : '2017-04-22T01:05:00.299Z'
                },
                {
                    'who'    : '5725a680b8d240c011dd224b',
                    'message': 'We are losing money! Quick!',
                    'time'   : '2017-04-22T01:10:00.299Z'
                }
            ]
        },
        {
            'id'    : '3725a6809413bf8a0a5272b4',
            'dialog': [
                {
                    'who'    : '5725a6809413bf8a0a5272b1',
                    'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                    'time'   : '2017-04-22T02:10:00.299Z'
                }
            ]
        }
    ];

    user = {
        'id'      : '5725a6802d10e277a0f35724',
        'name'    : 'John Doe',
        'avatar'  : 'assets/images/avatars/profile.jpg',
        'status'  : 'online',
        'mood'    : 'it\'s a status....not your diary...',
        'chatList': [
            {
                'id'             : '1725a680b3249760ea21de52',
                'contactId'      : '5725a680b3249760ea21de52',
                'name'           : 'Alice Freeman',
                'unread'         : 4,
                'lastMessage'    : 'You are the worst!',
                'lastMessageTime': '2017-06-12T02:10:18.931Z'
            },
            {
                'id'             : '2725a680b8d240c011dd2243',
                'contactId'      : '5725a680b8d240c011dd224b',
                'name'           : 'Josefina',
                'unread'         : null,
                'lastMessage'    : 'We are losing money! Quick!',
                'lastMessageTime': '2017-02-18T10:30:18.931Z'
            },
            {
                'id'             : '3725a6809413bf8a0a5272b4',
                'contactId'      : '5725a6809413bf8a0a5272b1',
                'name'           : 'Velazquez',
                'unread'         : 2,
                'lastMessage'    : 'Quickly come to the meeting room 1B, we have a big server issue',
                'lastMessageTime': '2017-03-18T12:30:18.931Z'
            }
        ]
    };

    onChatSelected: BehaviorSubject<any>;
    onContactSelected: BehaviorSubject<any>;
    onChatsUpdated: Subject<any>;
    onUserUpdated: Subject<any>;
    onLeftSidenavViewChanged: Subject<any>;
    onRightSidenavViewChanged: Subject<any>;
    selectedChatRoom = new Subject<any>();
    selectedThread = new Subject<any>();
    activeChatRoomID = -1;

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise<void>((resolve, reject) => {
            resolve();
        });
    }

    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    getChat(contactId): Promise<any> | any
    {
        const chatItem = this.user.chatList.find((item) => {
            return item.contactId === contactId;
        });

        // Create new chat, if it's not created yet.
        if ( !chatItem )
        {
            this.createNewChat(contactId).then((newChats) => {
                this.getChat(contactId);
            });
            return;
        }

        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-chats/' + chatItem.id)
                .subscribe((response: any) => {
                    const chat = response;

                    const chatContact = this.contacts.find((contact) => {
                        return contact.id === contactId;
                    });

                    const chatData = {
                        chatId : chat.id,
                        dialog : chat.dialog,
                        contact: chatContact
                    };

                    this.onChatSelected.next({...chatData});

                }, reject);

        });

    }

    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewChat(contactId): Promise<any>
    {
        return new Promise((resolve, reject) => {

            const contact = this.contacts.find((item) => {
                return item.id === contactId;
            });

            const chatId = FuseUtils.generateGUID();

            const chat = {
                id    : chatId,
                dialog: []
            };

            const chatListItem = {
                contactId      : contactId,
                id             : chatId,
                lastMessageTime: '2017-02-18T10:30:18.931Z',
                name           : contact?.name,
                unread         : null
            };

            // Add new chat list item to the user's chat list
            // @ts-ignore
            this.user.chatList.push(chatListItem);

            // Post the created chat
            this._httpClient.post('api/chat-chats', {...chat})
                .subscribe((response: any) => {

                    // Post the new the user data
                    this._httpClient.post('api/chat-user/' + this.user.id, this.user)
                        .subscribe(newUserData => {

                            // Update the user data from server
                            this.getUser().then(updatedUser => {
                                this.onUserUpdated.next(updatedUser);
                                resolve(updatedUser);
                            });
                        });
                }, reject);
        });
    }

    /**
     * Select contact
     *
     * @param contact
     */
    selectContact(contact): void
    {
        this.onContactSelected.next(contact);
    }

    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void
    {
        this.user.status = status;
    }

    /**
     * Update user data
     *
     * @param userData
     */
    updateUserData(userData): void
    {
        this._httpClient.post('api/chat-user/' + this.user.id, userData)
            .subscribe((response: any) => {
                    this.user = userData;
                }
            );
    }

    /**
     * Update the chat dialog
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    updateDialog(chatId, dialog): Promise<any>
    {
        return new Promise((resolve, reject) => {

            const newData = {
                id    : chatId,
                dialog: dialog
            };

            this._httpClient.post('api/chat-chats/' + chatId, newData)
                .subscribe(updatedChat => {
                    resolve(updatedChat);
                }, reject);
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            resolve(this.contacts);
        });
    }

    /**
     * Get chats
     *
     * @returns {Promise<any>}
     */
    getChats(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            resolve(this.chats);
        });
    }

    getSelectedChatRoom(): any
    {
        return this.selectedChatRoom.asObservable();
    }


    getSelectedThread(): Observable<any>
    {
        return this.selectedThread.asObservable();
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            resolve(this.user.chatList[0]);
        });
    }
}
