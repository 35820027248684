import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RestService } from '../../../service/api/rest.service';

@Injectable( {providedIn: 'root'})
export class ManagementService {
    onContactsChanged: BehaviorSubject<any>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    onDeleteAll: BehaviorSubject<any>;

    items: any [];
    user: any;
    selectedContacts: string[] = [];
    baseUser = 'users';
    baseClinic = 'clinics';
    baseCoupons = 'discount_codes';
    searchText: string;
    filterBy: string;

  constructor( private rest: RestService) {
      // Set the defaults
      this.onContactsChanged = new BehaviorSubject([]);
      this.onSelectedContactsChanged = new BehaviorSubject([]);
      this.onUserDataChanged = new BehaviorSubject([]);
      this.onSearchTextChanged = new Subject();
      this.onFilterChanged = new Subject();
      this.onDeleteAll = new BehaviorSubject<string>('');
  }


    /**
     * Select contacts
     *
     * @param filterParameter
     * @param filterValue
     */
    selectContacts(filterParameter?, filterValue?): void
    {
        this.selectedContacts = [];

        // If there is no filter, select all contacts
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedContacts = [];
            this.items.map(contact => {
                this.selectedContacts.push(contact.id);
            });
        }

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Toggle selected contact by id
     *
     * @param id
     */
    toggleSelectedContact(id): void
    {
        // First, check if we already have that contact as selected...
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);

                // Trigger the next event
                this.onSelectedContactsChanged.next(this.selectedContacts);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedContacts.push(id);

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Deselect contacts
     */
    deselectItems(): void
    {
        this.selectedContacts = [];

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }


    deleteUsers(params): any
    {
        return this.rest.post(`${(this.baseUser)}/delete_multiple_users`, params).pipe(tap(data => {
            return data;
        }));
    }

    deleteGroups(params): any
    {
        return this.rest.post(`${(this.baseClinic)}/delete_multiple_clinics`, params).pipe(tap(data => {
            return data;
        }));
    }


    pauseUsers(params): any
    {
        return this.rest.put(`${(this.baseUser)}/pause_user`, params).pipe(tap(data => {
            return data;
        }));
    }

    pauseGroups(params): any
    {
        return this.rest.put(`${(this.baseClinic)}/pause_clinic`, params).pipe(tap(data => {
            return data;
        }));
    }

    resumeUsers(params): any
    {
        return this.rest.put(`${(this.baseUser)}/resume_user`, params).pipe(tap(data => {
            return data;
        }));
    }

    resumeGroups(params): any
    {
        return this.rest.put(`${(this.baseClinic)}/resume_clinic`, params).pipe(tap(data => {
            return data;
        }));
    }

    // Confirm Admin
    confirmAdmin(params): any {
        return this.rest.post(`${(this.baseUser)}/admin_login`, params).pipe(tap(data => {
            return data;
        }));
    }

//    Add Coupons
    addCoupons(params): any {
        return  this.rest.post(`${this.baseCoupons}`, params);
    }

    getCoupons(params): any {
        return this.rest.get(`${this.baseCoupons}`, params).pipe(tap(data => {
            return data;
        }));
    }

    assignDiscountCode(params): any {
        return this.rest.put(`${this.baseCoupons}/assign_discount_code`, params).pipe(tap(data => {
            return data;
        }));
    }

    deleteDiscountCode(params): any
    {
        return this.rest.post(`${this.baseCoupons}/delete_discount_codes`, params);
    }

    editDiscountCode(id, params): any
    {
        return this.rest.put(`${this.baseCoupons}/${id}`, params).pipe(tap(data => {
            return data;
        }));
    }
}
