import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import {
    FusePerfectScrollbarDirective
} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import { NewAuthService } from '../../../../../service/api/newAuth.service';
import { User } from '../../../../../types';
import { GroupDialogComponent } from '../../../../../main/apps/components/dialogs/group/group.component';
import { MatDialog } from '@angular/material/dialog';
import { ActionCableService } from '../../../../../service/socket/action-cable.service';
import {
    AdminPanelPasswordDialogComponent
} from '../../../../../main/apps/components/dialogs/admin-panel-password-dialog/admin-panel-password-dialog.component';


@Component({
    selector     : 'navbar-vertical-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;
    name: string;
    email: string;
    imageSrc: string;
    page = 1;
    private userUpdateSubscription: Subscription;
    private currentUser: User;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param _authService
     * @param {Router} _router
     * @param newAuthDataService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _authService: NewAuthService,
        private _router: Router,
        private newAuthDataService: NewAuthDataService,
        private _matDialog: MatDialog,
        private actionCableService: ActionCableService,
        private baseService: NewAuthService,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, {static: true})
    set directive(theDirective: FusePerfectScrollbarDirective)
    {
        if ( !theDirective )
        {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                    });
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._fuseSidebarService.getSidebar('navbar') )
                    {
                        this._fuseSidebarService.getSidebar('navbar').close();
                    }
                }
            );
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });
        this.newAuthDataService.getUser().subscribe((user: User) => {
            this.currentUser = user;
            this.name = `${user.first_name} ${user.last_name}`;
            this.email = user.email;
            this.imageSrc = user.image;
            this.checkAdmin();
        });
        this.checkUserUpdate();


    }

    checkAdmin(): void {
        if ( !this.currentUser?.authorized_admin )
        {
            this._fuseNavigationService.updateNavigationItem('user-management', {
                function: () => {
                    this._matDialog.open(AdminPanelPasswordDialogComponent, {
                        panelClass: 'back-office',
                        data: {
                            actionType: 'BackOffice'
                        }
                    }).afterClosed().subscribe((data) => {
                        if ( data[0] === 'yes' )
                        {
                            this._router.navigate(['/backoffice/user-management']);
                        }
                    });
                }
            });
            this._fuseNavigationService.updateNavigationItem('group-management', {
                function: () => {
                    this._matDialog.open(AdminPanelPasswordDialogComponent, {
                        panelClass: 'back-office',
                        data: {
                            actionType: 'BackOffice'
                        }
                    }).afterClosed().subscribe((data) => {
                        if ( data[0] === 'yes' )
                        {
                            this._router.navigate(['/backoffice/group-management']);
                        }
                    });
                }
            });
            this._fuseNavigationService.updateNavigationItem('coupon-management', {
                function: () => {
                    this._matDialog.open(AdminPanelPasswordDialogComponent, {
                        panelClass: 'back-office',
                        data: {
                            actionType: 'BackOffice'
                        }
                    }).afterClosed().subscribe((data) => {
                        if ( data[0] === 'yes' )
                        {
                            this._router.navigate(['/backoffice/coupon-management']);
                        }
                    });
                }
            });
        }
        else if ( this.currentUser?.authorized_admin)
        {
            this._fuseNavigationService.updateNavigationItem('user-management', {
                url : '/backoffice/user-management',
                function : () => {
                }
            });
            this._fuseNavigationService.updateNavigationItem('group-management', {
                url : '/backoffice/group-management',
                function : () => {
                }
            });
            this._fuseNavigationService.updateNavigationItem('coupon-management', {
                url : '/backoffice/coupon-management',
                function : () => {
                }
            });
        }
    }
    checkUserUpdate(): void
    {
        if ( !!this.newAuthDataService.getSignedInUser() )
        {
            this.userUpdateSubscription = this.actionCableService.getUserProfileUpdate().pipe(takeUntil(this._unsubscribeAll)).subscribe((_data) => {

                if ( _data && this.currentUser.id === _data.user.user.id )
                {
                    const user = {..._data.user.user};
                    user.image = _data.user.user.file?.medium_url || 'assets/images/avatars/profile.jpg';
                    this.newAuthDataService.userSubject.next(user);
                    this.baseService.updateOneInCache(user);
                    this.newAuthDataService.socketUserUpdate(_data.user);
                }

            });
        }
    }
    OpenDialog(): any  {
        this._matDialog.open(GroupDialogComponent, {
            panelClass: 'group-card-dialog',
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }

    openGroupCreateModal(): void{
        this._matDialog.open(GroupDialogComponent, {
            panelClass: 'group-card-dialog',
        });
    }

    onScrollDown(): void {
        const page = ++this.page;
        this.newAuthDataService.getApprovedGroups(page, false).subscribe();
    }
}
