import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { RestService } from '../../service/api/rest.service';
import { Thread } from './thread.model';
import { ICategory } from 'app/types/Settings';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ThreadsEntityService extends EntityCollectionServiceBase<Thread>
{
    private _selectedCategory: ICategory | undefined = undefined;
    private _defaultCategory: ICategory | undefined = undefined;
    public readonly $selectedCategory = new Subject<ICategory>();
    public readonly $defaultCategory = new Subject<ICategory>();

    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private rest: RestService)
    {
        super('Thread', serviceElementsFactory);
    }

    get selectedCategory() {
        return Object.assign({}, this._selectedCategory);
    }

    set selectedCategory(category: ICategory | undefined) {
        this._selectedCategory = category;
        this.$selectedCategory.next(this._selectedCategory);
    }

    get defaultCategory() {
        return Object.assign({}, this._defaultCategory);
    }

    set defaultCategory(category: ICategory | undefined) {
        this._defaultCategory = category;
        this.$defaultCategory.next(this._defaultCategory);
    }
}
