<div fxLayout="column" fxLayoutAlign="center center" *ngIf="qrCode">
  <img [src]="qrCode?.download_url" (load)="loading = false" />

  <button
    mat-raised-button
    [color]="!downloading ? 'accent' : 'light'"
    (click)="download()"
    *ngIf="!loading"
  >
    <span *ngIf="!downloading"> Download Now </span>
    <mat-progress-spinner
      color="accent"
      class="mx-auto custom-template"
      mode="indeterminate"
      diameter="36"
      strokeWidth="2"
      *ngIf="downloading">
    </mat-progress-spinner>
  </button>

  <div style="padding: 48px" *ngIf="loading">
    <mat-progress-spinner
      color="accent"
      class="mx-auto custom-template"
      mode="indeterminate"
      diameter="48"
      strokeWidth="2">
    </mat-progress-spinner>
  </div>
</div>