import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    declarations: [ConfirmationDialogComponent],
    exports     : [
        ConfirmationDialogComponent
    ],
    imports: [
        FuseSharedModule,
        MatToolbarModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        TranslateModule,
        MatFormFieldModule,
        MatButtonModule,
    ]
})
export class ConfirmationDialogModule
{
}
