import { Injectable } from "@angular/core";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { HttpClient } from "@angular/common/http";
import { RestService } from "../../service/api/rest.service";
import { map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { GetTrackerDataPayload, GetTrackerDataResponse, GetTrackerSummaryPayload, GetTrackerSummaryResponse, TimeTrackerSummary, TimeTrackerTask } from "./time-tracker-task.model";

@Injectable()
export class TimeTrackerTaskDataService extends DefaultDataService<TimeTrackerTask> {
  private _baseClinic = "clinics";
  calendarViewMode = new Subject();

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private rest: RestService  ) {
    super("TimeTrackerTask", http, httpUrlGenerator);
  }

  getTrackerData(
    clinicId: number,
    payload: GetTrackerDataPayload
  ): Observable<TimeTrackerTask[]> {
    return this.rest
      .post(`${this._baseClinic}/${clinicId}/clinic_check_ins/tracker_data`, {
        user_ids: payload.user_ids || [],
        start_date: payload.start_date || "",
        end_date: payload.end_date || "",
      })
      .pipe(
        map((resp: GetTrackerDataResponse) => {
          const tasks: TimeTrackerTask[] = [];

          resp.check_ins.forEach((task: TimeTrackerTask) => {
            tasks.push(task);
          });

          return tasks;
        })
      );
  }

  getTrackerSummary(
    clinicId: number,
    date: string,
    payload: GetTrackerSummaryPayload
  ): Observable<TimeTrackerSummary[]> {
    return this.rest
      .post(
        `${this._baseClinic}/${clinicId}/clinic_check_ins/tracker_time_sum_data`,
        {
          user_ids: payload.user_ids || [],
          date
        }
      )
      .pipe(
        map((resp: GetTrackerSummaryResponse) => {
          const summaries: TimeTrackerSummary[] = [];

          resp.check_ins.forEach((summary: TimeTrackerSummary) => {
            summaries.push(summary);
          });

          return summaries;
        })
      );
  }
}
