<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Confirm Password</span>
            <button mat-icon-button (click)="matDialogRef.close('close')" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div matDialogContent class="p-12 pb-1 m-0">
        <form novalidate [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
            <mat-form-field class="full-width" appearance="outline" >
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password" >
                <mat-error *ngIf="passwordForm.get('password')?.errors?.required">Password is Required</mat-error>
                <mat-error *ngIf="passwordForm.get('password')?.errors?.serverError">
                    {{ passwordForm.get('password')?.errors?.serverError.trim() }}
                </mat-error>

            </mat-form-field>

            <div class="m-12" fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button color="accent" type="submit">
                    Submit
                </button>
            </div>
        </form>
    </div>
</div>
