<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{'GROUP_INVITATION.invite_to' | translate}} {{currentGroup?.name}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="overflowContent">

        <form [formGroup]="inviteForm">

            <div *ngIf="!showIcon" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Email</mat-label>
                    <mat-chip-list #chipList aria-label="Fruit selection">
                        <mat-chip [color]="(fruit.invalid) ? 'warn' : ''" selected *ngFor="let fruit of userEmails"
                                  [selectable]="selectable"
                                  [removable]="removable" (removed)="remove(fruit, 'EMAIL')">
                            {{fruit.value}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Email"
                               formControlName="email"
                               [matChipInputFor]="chipList"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="addOnBlur"
                               (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                </mat-form-field>
            </div>
            <h2 class="no-background"><span>{{'GROUP_INVITATION.or' | translate}}</span></h2>
            <div>
                <ng-select [items]="contacts"
                           [virtualScroll]="true"
                           appearance="outline"
                           placeholder="Select Contact"
                           bindLabel="contact_name"
                           bindValue="mobile_no"
                           (scrollToEnd)="myDocContacts()"
                           (change)="onContactSelected()"
                           formControlName="selectedContact">
                </ng-select>
            </div>
            <div *ngIf="!showIcon" fxLayout="row" fxLayoutAlign="start start">
                <div class="mainCon">
                <mat-form-field appearance="outline"class="countrycode" id="countryC" >
                    <ngx-mat-intl-tel-input
                            [enablePlaceholder]="true"
                            [onlyCountries]="['dk']"
                            [enableSearch]="false"
                            formControlName="countryCode"
                            name="mobileNo" #mobileNo >
                    </ngx-mat-intl-tel-input>
                </mat-form-field>
                </div>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Phone</mat-label>
                    <mat-chip-list #numberChipList aria-label="Fruit selection">
                        <mat-chip [color]="(fruit.invalid)?'warn':''" selected *ngFor="let fruit of userNumbers"
                                  [selectable]="selectable"
                                  [removable]="removable" (removed)="remove(fruit, 'NUMBER')">
                            {{fruit.value}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Phone"
                               formControlName="phone"
                               type="number"
                               [matChipInputFor]="numberChipList"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="addOnBlur"
                               (matChipInputTokenEnd)="addNumber($event)">
                    </mat-chip-list>
<!--                    <mat-hint> Please add number without +45</mat-hint>-->
                </mat-form-field>
            </div>
<!--            <ng-container *ngIf="showIcon">-->
<!--                <div class="card-breadcrumb mt-8 mb-32 px-24" fxLayout="row"-->
<!--                     fxLayoutAlign="start center"-->
<!--                     fxLayout.xs="column">-->
<!--                    <profile-image [src]="inviteData.file? inviteData.file.src: 'assets/images/avatars/profile.jpg' "-->
<!--                                   class="avatar mr-0 mr-sm-16"></profile-image>-->
<!--                    <span>{{ inviteData.first_name + ' ' + inviteData.last_name }}</span>-->
<!--                </div>-->
<!--            </ng-container>-->
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>{{'GROUP_INVITATION.personal_message' | translate}}</mat-label>
                    <input name="patientBoardMessage" formControlName="patientBoardMessage" matInput>
                    <mat-error>{{'GROUP_INVITATION.message' | translate}} {{'SLIDE.is_required' | translate}}</mat-error>
                </mat-form-field>
            </div>
<!--            <mat-label>{{'GROUP_INVITATION.invite_to' | translate}}:</mat-label>-->

<!--            <div class="check-overflow" fxLayout="column" fxLayoutAlign="start start">-->
<!--                <div *ngFor="let group of groupsList; let i = index">-->
<!--                    <mat-checkbox class="example-margin" (change)="showOptions($event, i)">-->
<!--                        {{group.name}}-->
<!--                    </mat-checkbox>-->
<!--                </div>-->
<!--            </div>-->
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button mat-raised-button color="accent" class="post-button mr-4" (click)="matDialogRef.close(['cancel'])"
                aria-label="cancel">{{'TOOLBAR.cancel' | translate}}</button>
        <button mat-raised-button color="accent" class="post-button" (click)="sendInvite()"
                aria-label="send">{{'GROUP_INVITATION.send' | translate}}</button>
<!--        <button-->
<!--                mat-button-->
<!--                (click)="matDialogRef.close(['cancel'])"-->
<!--                aria-label="Cancel">-->
<!--            {{'TOOLBAR.cancel' | translate}}-->
<!--        </button>-->

<!--        <button-->
<!--                mat-button-->
<!--                class="save-button"-->
<!--                (click)="sendInvite()"-->
<!--                aria-label="Send">-->
<!--            {{'GROUP_INVITATION.send' | translate}}-->
<!--        </button>-->

    </div>
</div>
