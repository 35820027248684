<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title"> {{title}}</span>
            <button mat-icon-button (click)="matDialogRef.close(['close'])" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content class="p-12 pb-0 m-0">
        <h2>{{message | translate}}</h2>
    </div>
    <div mat-dialog-actions class="m-12" fxLayout=" row" fxLayoutAlign="end center">
        <button mat-raised-button color="accent" class="post-button" (click)="matDialogRef.close(['yes'])"
                aria-label="update">{{currentClinic?.paymentMethods?.length === 0 ? 'Register Card' : 'SETTINGS.yes' | translate}}</button>
        <button mat-raised-button color="accent" class="post-button" (click)="matDialogRef.close(['no'])"
                aria-label="delete">{{currentClinic?.paymentMethods?.length === 0 ? 'Cancel' : 'SETTINGS.no' | translate}}</button>
    </div>
</div>
