<div class="dialog-content-wrapper"  >
    <mat-toolbar matDialogTitle class="mat-accent m-0" >
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{'CATEGORIES.folder_management' | translate}}</span>

            <button mat-icon-button (click)="matDialogRef.close(['close',isStateChanged])" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div
            infinite-scroll
            [scrollWindow]="false"
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="0"
            [infiniteScrollDisabled]="scrollMore"
            (scrolled)="onScrollDown()"
            fusePerfectScrollbar
            [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
            class="h-400">
    <div mat-dialog-content class="p-12 pb-0 m-0">
        <div fxLayout="row" class="m-4" fxLayoutAlign="space-between center">

                <mat-form-field appearance="outline"   class="formFieldWidth">
                    <mat-label>{{'SEARCH.search_in_folders' | translate}}</mat-label>
                    <input [(ngModel)]="searchText" matInput placeholder="{{'SEARCH.search_folders' | translate}}"
                           #searchFoldersElement
                           (ngModelChange)="modalChange($event)">
                    <span *ngIf="resetSearchText" matSuffix><mat-icon
                            (click)="onClickCloseResetForm()" class="secondary-text"
                            matTooltip="Clear Search">close</mat-icon></span>
                </mat-form-field>
            <button
                    class="post-button mb-20"
                    mat-raised-button color="accent"
                    (click)="searchFolders()"
                    aria-label="SEARCH">
                {{'SEARCH.search' | translate}}
            </button>
        </div>
        <div mat-dialog-actions class="m-4" fxLayout=" row" fxLayoutAlign="space-between center">
            <form [formGroup]="categoryUpdateForm"  class="footerFormWidth"(ngSubmit)="categoryUpdate()">
                <div fxLayout="row" fxLayoutAlign="start start" >
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{'CATEGORIES.name_folder' | translate}}</mat-label>
                        <mat-icon matSuffix class="secondary-text">name</mat-icon>
                        <input name="name" formControlName="name" matInput type="text">
                        <mat-error>{{'CONTACT.name' | translate}} {{'GROUPS.cannot_be_empty' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </form>
            <button *ngIf="create"
                    class="post-button mb-20"
                    mat-raised-button color="accent"
                    (click)="categoryUpdate()"
                    aria-label="SAVE">
                {{'CONTACT.create' | translate}}
            </button>
            <button *ngIf="!create"
                    class="post-button"
                    mat-raised-button color="accent"
                    (click)="categoryUpdate()"
                    aria-label="SAVE">
                {{'THREAD.update' | translate}}
            </button>
        </div>
    </div>
        <div>
            <mat-card *ngFor=" let category of categories" class="mb-4 mx-8">
                <div fxLayout="row" fxLayoutAlign="space-between space-between">
                        <p class="mt-4" > {{category?.categoryName}}</p>
                <div fxLayout="row" *ngIf="category.isDefault !== true && category.activity_posts_folder === false">
                    <button mat-icon-button matTooltip="Edit" *ngIf="!category.isHidden" >
                    <mat-icon (click)="updateCategory(category)" >edit</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Delete" *ngIf="!category.isHidden">
                    <mat-icon class="ml-4" (click)="openDeleteModal(category)">delete</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Hide" (click)="hideCategory(true , category)" *ngIf="!category.isHidden">
                    <mat-icon class="ml-4"> remove_red_eye</mat-icon>
                    </button>
                    <button mat-icon-button (click)="hideCategory(false , category)"  matTooltip="Show" *ngIf="category.isHidden">
                        <mat-icon class="4">visibility_off</mat-icon>
                    </button>
                </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
