import { Component, Inject, OnInit } from '@angular/core';
import { GroupsDataService } from '../../../../../store/group/groups-data.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import { User } from '../../../../../types';
import { ToastrService } from 'ngx-toastr';
import { ThreadDialogComponent } from '../thread/thread.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ThreadsDataService } from '../../../../../store/thread/threads-data.service';
import { Thread } from '../../../../../store/thread/thread.model';
import { IGroup } from '../../../../../store/group/group.model';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';


@Component({
  selector: 'app-share-thread-dialog',
  templateUrl: './share-thread-dialog.component.html',
  styleUrls: ['./share-thread-dialog.component.scss']
})
export class ShareThreadDialogComponent implements OnInit {
    page = 0;
    groupListing: IGroup[] = [];
    searchField: FormControl;
    post: Thread;
    shareContent: string;
    contactId: number;
    currentUser: User;
    myDocId: number;

    constructor(
        private  authService: NewAuthDataService,
        private _matDialog: MatDialog,
        private modalRef: MatDialogRef<ShareThreadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public matDialogRef: MatDialogRef<ShareThreadDialogComponent>,
        private threadService: ThreadsDataService,
        private groupDataService: GroupsDataService,
        private progressBarService: FuseProgressBarService,
        private fb: FormBuilder,
        private toast: ToastrService,
    )
    {
        this.getApprovedGroups();
    }

  ngOnInit(): void {
      this.getCurrentUser();
      this.post = this._data?.post;
      this.shareContent = this._data.shareContent;
      this.contactId = this._data.contactId;
      this.searchField = this.fb.control('');
      this.searchField.valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged()
      ).subscribe(value => {
          this.searchGroupByName();
      });
  }

    getApprovedGroups(): void
    {
        const groupData = {
            'page'          : ++this.page,
            'request_status': 'approved',
        };
        this.groupDataService.getGroupListing(groupData).subscribe((data) => {
            this.groupListing = [...this.groupListing, ...data.groups];
        });
    }

    searchGroupByName(): void
    {
        if ( this.searchField.value.length > 0 )
        {
            this.groupDataService.getGroupListing({page: 1, search: this.searchField.value, request_status: 'approved' }).subscribe((res) => {
                this.groupListing = [...res?.groups];
            });
        }
        else{
            this.page = 0;
            this.getApprovedGroups();
        }
    }


   getCurrentUser(): void {
      this.currentUser = this.authService.getSignedInUser();
      this.myDocId = this.currentUser?.my_doc?.id;
   }

    shareThread(clinicId: number, thread: any, clinic: any): void
    {
        let sharedThread = {...thread};
        const id = clinicId;
        sharedThread = {...sharedThread, clinicId: id};
        this._matDialog.open(ThreadDialogComponent, {
            panelClass: 'card-dialog',
            data      : {
                new          : false,
                threadData   : sharedThread,
                clinicId     : clinicId,
                share        : true,
                currentClinic: clinic,
                sharedClinicId : this.post.clinicId
            }
        }).afterClosed().subscribe((res) => {
            if ( res && res[0] === 'close' )
            {
                this.modalRef.close(['close']);
            }
        });
    }

    shareContact(clinicId): void
    {
        this.progressBarService.show();
        const params = {
            contact_id: this.contactId,
            clinic_id : clinicId
        };
        this.groupDataService.shareContact(params).subscribe((data) => {
            this.toast.success(data.message);
            this.modalRef.close(['close']);
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.errors.join(', '), 'Error');
        });
    }
}
