import { EntityDataModuleConfig, EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
    User            : {},
    Group           : {},
    Thread          : {},
    GroupUser       : {},
    Task            : {},
    TimeTrackerTask : {},
    Friend          : {},
    Notification    : {},
    SystemUsers     : {},
    ChatRooms       : {},
    UploadingFiles  : {},
    DocRead         : {},
};
const pluralNames = {User: 'Users'};

export const entityConfig: EntityDataModuleConfig = {
    entityMetadata,
    pluralNames
};
