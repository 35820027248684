import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { Observable, of, timer } from "rxjs";
import { distinctUntilChanged, map, withLatestFrom } from "rxjs/operators";

@Pipe({
  name: "amTimeAgoCustom",
})
export class AmTimeAgoCustomPipe implements PipeTransform {
 transform(value: Date): Observable<string> {
    const source = timer(0, this.getUpdatedInterval(value)).pipe(
      withLatestFrom(of(value)), 
      map(([count, val]) => this.calculateTimeAgo(val)), 
      distinctUntilChanged() 
    );

    return source;
  }

  private calculateTimeAgo(value: Date): string {
    const now = moment();
    const targetDate = moment(value);
    const minutesDiff = now.diff(targetDate, "minutes");
    const hoursDiff = now.diff(targetDate, "hours");

    if (minutesDiff < 60) {
      return `${minutesDiff} minute ago`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff} hour ago`;
    } else {
      return targetDate.format("DD/MM-YYYY HH:MM");
    }
  }

  private getUpdatedInterval(value: Date): number {
    const now = moment();
    const hoursDiff = now.diff(moment(value), "hours");

    if (hoursDiff < 1) {
      return 60 * 1000;
    } else {
      return hoursDiff * 60 * 60 * 1000;
    }
  }
}
