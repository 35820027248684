import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import { ToastrService } from 'ngx-toastr';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ManagementService } from '../../../backoffice/management.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GroupsDataService } from '../../../../../store/group/groups-data.service';

@Component({
    selector     : 'app-admin-panel-password-dialog',
    templateUrl  : './admin-panel-password-dialog.component.html',
    styleUrls    : ['./admin-panel-password-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AdminPanelPasswordDialogComponent implements OnInit, OnDestroy
{
    passwordForm: FormGroup;
    passwordSubscription: Subscription;
    private actionType: string;
    private clinicId: any;

    constructor(public matDialogRef: MatDialogRef<AdminPanelPasswordDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private _data: any,
                private fb: FormBuilder,
                private newAuthDataService: NewAuthDataService,
                private toast: ToastrService,
                private _progressBarService: FuseProgressBarService,
                private managementService: ManagementService,
                private groupDataService: GroupsDataService,
                private _route: ActivatedRoute)
    {
    }

    ngOnInit(): void
    {
        this.passwordForm = this.fb.group({
            password: ['', Validators.required]
        });
        this.actionType = this._data.actionType;

    }

    ngOnDestroy(): void
    {
        if ( this.passwordSubscription )
        {
            this.passwordSubscription.unsubscribe();
        }
    }

    onSubmit(): void
    {
        if ( this.passwordForm.valid )
        {
            const params = {
                password: this.passwordForm.value.password
            };
            if ( this.actionType && this.actionType === 'BackOffice' )
            {
                this.passwordSubscription = this.managementService.confirmAdmin(params).subscribe((data) => {

                    this.matDialogRef.close(['yes']);
                    this.newAuthDataService.getUpdatedUser();

                }, (error) => {
                    this.passwordForm?.get('password')?.setErrors({serverError: error.errors.join(',')});
                });
            } else if ( this.actionType && this.actionType === 'FileDownload' )
            {
                this._progressBarService.show();
                const param = {
                    password: this.passwordForm.value.password
                };
                this.groupDataService.verifyBackUpPassword(this._data?.currentGroup.id, param).subscribe((data) => {
                    this.matDialogRef.close(['goToGroupBackUp']);
                    this._progressBarService.hide();
                }, (error => {
                    this.passwordForm?.get('password')?.setErrors({serverError: error.errors.join(',')});
                    this._progressBarService.hide();
                }));
            }
        } else
        {
            this.passwordForm.markAllAsTouched();
        }
    }
}
