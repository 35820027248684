<form novalidate [formGroup]="threadForm" xmlns="http://www.w3.org/1999/html">
  <div class="dialog-content-wrapper">
    <mat-toolbar
      matDialogTitle
      class="accent m-0"
      fxFlex="1 0 auto"
      fxLayout="row"
    >
      <!--                Header-->
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <!--                    LEFT-->
        <div fxLayout="row" fxLayoutAlign="start center">
          <!-- Priorities -->
          <div class="due-date" fxFlex="0 1 auto">
            <button
              *ngIf="prioritiesList"
              mat-icon-button
              [matMenuTriggerFor]="prioritiesMenu"
              class="section-header-point-cursor"
            >
              <mat-icon matTooltip="{{ 'THREAD.priority' | translate }}"
                >label</mat-icon
              >
            </button>

            <mat-menu
              #prioritiesMenu="matMenu"
              [overlapTrigger]="false"
              fxLayout="column"
            >
              <mat-radio-group
                aria-label="Select an option"
                formControlName="priority"
              >
                <mat-checkbox
                  [checked]="prioritycheck == priority.name"
                  (change)="radio($event, priority.name)"
                  *ngFor="let priority of this.priorities; index as index"
                  class="p-20"
                  [value]="priority.name"
                  fxLayout="row"
                  >{{ priority.name }}</mat-checkbox
                >
              </mat-radio-group>
            </mat-menu>
          </div>
          <!-- / Priorities -->

          <!-- ATTACHMENT -->
          <button
            mat-icon-button
            aria-label="Attachment"
            (click)="attachmentsC.pickFile()"
          >
            <mat-icon matTooltip="Attachments">attachment</mat-icon>
          </button>
          <!-- / ATTACHMENT -->

          <!-- Confirmation -->
          <div *ngIf="newThread" class="labels" fxFlex="0 1 auto">
            <button mat-icon-button>
              <mat-icon
                matTooltip="{{ 'THREAD.show_destination' | translate }}"
                *ngIf="!confirmation"
                class="mr-8"
                style="cursor: pointer"
                (click)="confirmationStatus($event)"
              >
                check_box_outline_blank
              </mat-icon>
              <mat-icon
                matTooltip="{{ 'THREAD.show_destination' | translate }}"
                *ngIf="confirmation"
                class="mr-8"
                style="cursor: pointer"
                (click)="confirmationStatus($event)"
              >
                check_box
              </mat-icon>
            </button>
          </div>

          <mat-icon matTooltip="Contact" *ngIf="contact">contacts</mat-icon>
          <!-- Notification -->
        </div>

        <!--                CENTER-->
        <div fxLayout="column" class="py-8" fxLayoutAlign="center center">
          <div
            class="card-breadcrumb px-24"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayout.xs="column"
          >
            <!--                    <profile-image [src]="userData.file?.thumb_url" class="avatar mr-0 mr-sm-16"></profile-image>-->
            <!--                    <span>{{ userData.first_name + ' ' + userData.last_name }}</span>-->
            <!--                    <mat-icon class="s-20">chevron_right</mat-icon>-->
            <button
              *ngIf="allowGroupEdit"
              color=""
              class="p-0"
              [matMenuTriggerFor]="groupNameMenu"
              mat-button
            >
              <span>{{ currentSelectedGroup?.name || "Select Group" }}</span>
            </button>
            <button *ngIf="!allowGroupEdit" color="" class="p-0" mat-button>
              <span>{{ currentSelectedGroup?.name || "Select Group" }}</span>
            </button>
            <mat-menu
              (close)="onCloseMenu()"
              #groupNameMenu="matMenu"
              [overlapTrigger]="false"
              class="mat-menu-height"
            >
              <button
                mat-menu-item
                (click)="groupSelection($event, i)"
                *ngFor="let group of groupsList; let i = index"
              >
                {{ group.name }}
              </button>
            </mat-menu>

            <ng-container *ngIf="currentSelectedGroup">
              <mat-icon class="s-20">chevron_right</mat-icon>

              <button
                color=""
                class="selected-category-button"
                [matMenuTriggerFor]="categoryNameMenu"
                mat-button
              >
                <span>{{
                  this.currentSelectedCategory?.name?.trim() || "Select Folder"
                }}</span>
              </button>

              <mat-menu
                #categoryNameMenu="matMenu"
                [overlapTrigger]="false"
                class="mat-menu-height"
              >
                <button (click)="openFolderCreationModal()" mat-menu-item>
                  Create New Folder
                </button>
                <mat-divider></mat-divider>
                <button
                  mat-menu-item
                  (click)="currentSelectedCategory = categories"
                  *ngFor="
                    let categories of currentSelectedGroup?.clinic_categories
                  "
                >
                  {{ categories.name }}
                </button>
              </mat-menu>
            </ng-container>
          </div>
          <span class="contact-details" *ngIf="contact">
            {{ contact?.contact_name }} {{ contact?.last_name }}
          </span>
        </div>

        <!--                END-->
        <div>
          <!-- Save Button -->
          <button
            [disabled]="saveButton"
            mat-icon-button
            type="submit"
            aria-label="Save Group"
            (click)="saveThread()"
          >
            <mat-icon
              *ngIf="newThread"
              matTooltip="{{ 'THREAD.save' | translate }}"
              >save</mat-icon
            >
            <mat-icon
              *ngIf="!newThread"
              matTooltip="{{ 'THREAD.update' | translate }}"
              >save</mat-icon
            >
          </button>
          <!-- Save Button -->

          <!-- CLOSE DIALOG BUTTON -->
          <button
            mat-icon-button
            (click)="matDialogRef.close()"
            aria-label="Close Dialog"
          >
            <mat-icon matTooltip="{{ 'THREAD.close' | translate }}"
              >close</mat-icon
            >
          </button>
        </div>

        <!-- / CLOSE DIALOG BUTTON -->
      </div>
    </mat-toolbar>
    <div class="loading-div" *ngIf="loadingProgress">
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    </div>
    <div mat-dialog-content class="py-24 px-0 m-0">
      <div class="sections">
        <!-- LABELS & Select memeber who could see SECTION -->
        <div
          *ngIf="prioritiesList"
          class="section"
          fxLayout="column"
          fxLayout.gt-xs="row"
        >
          <div fxFlex class="labels">
            <!--                        <div class="section-header " fxLayout="row" fxLayoutAlign="start center"-->
            <!--                             style="cursor: pointer">-->
            <!--                            <mat-icon class="mr-8">label</mat-icon>-->
            <!--                            <span class="section-title section-header-point-cursor"-->
            <!--                                  [matMenuTriggerFor]="prioritiesMenu">{{'THREAD.priority' | translate}}</span>-->
            <!--                        </div>-->

            <div class="section-content">
              <mat-chip-list class="label-chips">
                <ng-container
                  *ngFor="let priority of priorities; let i = index"
                >
                  <mat-chip
                    class="label-chip mb-4"
                    [ngClass]="
                      threadForm?.get('priority').value === priority.name
                        ? priority.color
                        : ''
                    "
                    fxLayoutAlign="start center"
                  >
                    <span
                      class="section-header-point-cursor"
                      (click)="prioritySelection(priority.name)"
                      >{{ priority.name | uppercase | translate }}</span
                    >
                  </mat-chip>
                </ng-container>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="px-24">
        <!-- NAME -->
        <div class="card-name px-24 mb-8">
          <rich-text-field
            formControlName="title"
            [data]="'<></>'"
            [placeholder]="'THREAD.title' | translate"
          ></rich-text-field>
        </div>
        <!-- / NAME -->
      </div>

      <!-- jornal -->
      <div fxLayout="row" fxLayoutAlign="start center" class="px-24">
        <div class="description pl-24 mb-16 full-width">
          <rich-text-field
            formControlName="description"
            [data]="'<></>'"
            [placeholder]="'THREAD.description' | translate"
          ></rich-text-field>
        </div>
        <mat-icon class="inverterd-pin mb-12" (click)="attachmentsC.pickFile()"
          >attachment</mat-icon
        >
      </div>
      <!-- / jornal -->

      <!-- SECTIONS -->
      <div class="sections">
        <!-- LABELS & Select memeber who could see SECTION -->
        <!--                <div *ngIf="prioritiesList" class="section"-->
        <!--                     fxLayout="column" fxLayout.gt-xs="row">-->

        <!--                    <div fxFlex class="labels">-->

        <!--                        <div class="section-header " fxLayout="row" fxLayoutAlign="start center"-->
        <!--                             style="cursor: pointer">-->
        <!--                            <mat-icon class="mr-8">label</mat-icon>-->
        <!--                            <span class="section-title section-header-point-cursor"-->
        <!--                                  [matMenuTriggerFor]="prioritiesMenu">{{'THREAD.priority' | translate}}</span>-->
        <!--                        </div>-->

        <!--                        <div class="section-content">-->
        <!--                            <mat-chip-list class="label-chips">-->

        <!--                                <ng-container *ngFor="let priority of priorities;let i = index">-->
        <!--                                    <mat-chip class="label-chip mb-4 "-->
        <!--                                              [ngClass]="threadForm?.get('priority').value === priority.name ? priority.color : ''"-->
        <!--                                              fxLayoutAlign="start center">-->
        <!--                                        <span class="section-header-point-cursor"-->
        <!--                                              (click)="prioritySelection(priority.name)">{{ priority.name | uppercase | translate}}</span>-->
        <!--                                    </mat-chip>-->
        <!--                                </ng-container>-->
        <!--                            </mat-chip-list>-->
        <!--                        </div>-->

        <!--                    </div>-->
        <!--                </div>-->

        <!-- ATTACHMENTS SECTION -->
        <div class="section">
          <div class="attachments">
            <!--                        <div class="section-header section-header-point-cursor" fxLayout="row" fxLayoutAlign="start">-->
            <!--                            <mat-icon class="mr-8">attachment</mat-icon>-->
            <!--                            <span class="section-title">{{'THREAD.attachments' | translate}}</span>-->
            <!--                        </div>-->

            <div class="section-content">
              <app-attachments
                #attachmentsC
                (filesRemoved)="removeAttachment($event)"
                [allowLargeFiles]="allowLargeFiles"
                [files]="threadData?.files"
              ></app-attachments>
              <!--                            <button *ngIf="currentSelectedGroup" mat-stroked-button class="add-attachment-button"-->
              <!--                                    aria-label="add attachment"-->
              <!--                                    (click)="attachmentsC.pickFile()">-->
              <!--                                {{'THREAD.add_attachments' | translate}}-->
              <!--                            </button>-->
            </div>
          </div>
        </div>
      </div>
      <!-- / SECTIONS -->
    </div>

    <div
      mat-dialog-actions
      class="m-0 p-16"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div
        class="section-header section-header-point-cursor"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <ng-container *ngIf="newThread">
          <mat-icon
            matTooltip="{{ 'THREAD.show_destination' | translate }}"
            *ngIf="!confirmation"
            class="mr-8"
            style="cursor: pointer"
            (click)="confirmationStatus($event)"
          >
            check_box_outline_blank
          </mat-icon>
          <mat-icon
            matTooltip="{{ 'THREAD.show_destination' | translate }}"
            *ngIf="confirmation"
            class="mr-8"
            style="cursor: pointer"
            (click)="confirmationStatus($event)"
          >
            check_box
          </mat-icon>
          <span class="section-title confirmation-pop"
            >{{ "THREAD.show_destination" | translate }}?</span
          >
        </ng-container>
      </div>
      <button
        *ngIf="newThread"
        mat-raised-button
        color="accent"
        class="post-button"
        (click)="saveThread()"
        aria-label="POST"
      >
        {{ "THREAD.save" | translate }}
      </button>
      <button
        *ngIf="!newThread && !isSharePost"
        mat-raised-button
        color="accent"
        class="post-button"
        (click)="saveThread()"
        aria-label="POST"
      >
        {{ "THREAD.update" | translate }}
      </button>
      <button
        *ngIf="isSharePost"
        mat-raised-button
        color="accent"
        class="post-button"
        (click)="sharePost()"
        aria-label="POST"
      >
        {{ "THREAD.share" | translate }}
      </button>
    </div>
  </div>
</form>
