<div id="dashboard-main" class="page-layout simple right-sidebar inner-scroll">

    <!-- SIDEBAR -->
    <fuse-sidebar class="sidebar" name="project-dashboard-right-sidebar-1" position="left" lockedOpen="gt-md">

        <!-- SIDEBAR CONTENT -->
        <div class="content p-24" fusePerfectScrollbar>
            <dashboard-tasks></dashboard-tasks>
        </div>
        <!-- / SIDEBAR CONTENT -->

    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center"
         infinite-scroll
         [scrollWindow]="false"
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="0"
         [infiniteScrollDisabled]="holdingScroll"
         (scrolled)="onScrollDown()"
         fusePerfectScrollbar>
        <!-- HEADER -->
        <div class="header accent p-24 pb-0" fxLayout="column" fxLayoutAlign="space-between">

            <div fxLayout="row" fxLayoutAlign="space-between start">

                <span class="mat-display-1 my-0 my-sm-24 welcome-message"
                      [@animate]="{value:'*',params:{x:'50px'}}">{{'DASHBOARD.WELCOME_BACK' | translate}}, {{name}}!
                </span>

                <button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
                        (click)="toggleSidebar('project-dashboard-right-sidebar-1')">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
        </div>
        <!-- / HEADER -->

        <mat-tab-group dynamicHeight>

            <mat-tab label="{{'DASHBOARD.HOME' | translate}}">

                <div>
                    <timeline fxFlex="100"
                              fxLayout="column" *fuseIfOnDom
                              fxLayoutAlign="center center"
                              [@animateStagger]="{value:'50'}"></timeline>
                </div>
            </mat-tab>

<!--            <mat-tab label="{{'DASHBOARD.budget_summary' | translate}}">-->

<!--                &lt;!&ndash; WIDGET GROUP &ndash;&gt;-->
<!--                <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom-->
<!--                     [@animateStagger]="{value:'50'}"></div>-->
<!--                &lt;!&ndash; / WIDGET GROUP &ndash;&gt;-->

<!--            </mat-tab>-->

        </mat-tab-group>

    </div>
    <!-- / CENTER -->
</div>
