import { NgModule } from '@angular/core';
import { TimelineComponent } from './timeline.component';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { TimelineService } from './timeline.service';
import { PostModule } from '../post/post.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateModule } from '@ngx-translate/core';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [TimelineComponent],
    exports     : [
        TimelineComponent
    ],
    imports: [
        MatIconModule,
        FuseSharedModule,
        MatButtonModule,
        MatTooltipModule,
        MatDividerModule,
        PostModule,
        InfiniteScrollModule,
        TranslateModule,
        IvyCarouselModule,
        RouterModule
    ],
    providers   : [TimelineService]
})
export class TimelineModule
{
}
