// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production  : false,
    hmr         : true,
    BASE_WEB_URL: 'https://dev.journalcare.dk',
    BASE_API_URL: 'https://devapi.journalcare.dk/api/v1/',
    BASE_WS_URL : 'wss://devapi.journalcare.dk/cable',
    BASE_SLID_URL : 'https://devslideshow.journalcare.dk',
    STRIPE_PUBLIC_KEY : 'pk_test_51KnzqzE57Y6kEfveRg9UJNnoBcbcrC5GSRABQdRZBN6QR3I2vm6yZlFjVqVMiZl24Aefk5nT5qCzgQ799piL6iPb00i1dyS7pE',
    // RECAPTCHA_SITE_KEY : '6LeGhiAhAAAAAIYTY1WSAGcPT8aJ9a-zo8ZGK164',
       RECAPTCHA_SITE_KEY : '6LehSBgqAAAAAKGlBTD8YoCL87JmRpayGIzxhJ21',

    stripe: {
        STRIPE_PUBLISHABLE_KEY: 'pk_test_51KnzqzE57Y6kEfveRg9UJNnoBcbcrC5GSRABQdRZBN6QR3I2vm6yZlFjVqVMiZl24Aefk5nT5qCzgQ799piL6iPb00i1dyS7pE',
        STRIPE_SECRET_KEY: 'sk_test_51KnzqzE57Y6kEfvetbQEAJSLczYETM9IINo1WDYLbYktsSA2R9cgFtsfJxczgonylf97T8werw0RhCWnUfK5amP100wh6kVsIQ'
      }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
