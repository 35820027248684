import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrefixConversionPipe } from './prefix-conversion.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { AmTimeAgoCustomPipe } from './am-time-ago-custom.pipe';




@NgModule({
  declarations: [
    PrefixConversionPipe,
    LinkifyPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
    AmTimeAgoCustomPipe
    
  ],
  imports: [CommonModule],
  exports: [PrefixConversionPipe, LinkifyPipe, SafeHtmlPipe, StripHtmlPipe,AmTimeAgoCustomPipe],
})
export class CommonPipeModule {}
