import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TaskDataService } from "app/store/task/task-data.service";
import { Thread } from "app/store/thread/thread.model";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { NgxMaterialTimepickerComponent } from "ngx-material-timepicker";
import { ThreadsEntityService } from "app/store/thread/threads-entity.service";

@Component({
  selector: "app-edit-checktime-dialog",
  templateUrl: "./edit-checktime-dialog.component.html",
  styleUrls: ["./edit-checktime-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditChecktimeDialogComponent implements OnInit {
  @ViewChild("endTimeToggleTimepicker")
  endTimeToggleTimepicker: NgxMaterialTimepickerComponent;
  check_outTime: FormControl;
  time: any;
  post: Thread;
  loadingProgress = false;
  taskStart: any;
  taskEnd: any;
  intervalId:any;

  constructor(
    public matDialogRef: MatDialogRef<EditChecktimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private toast: ToastrService,
    private taskDataService: TaskDataService,
    private threadEntity: ThreadsEntityService
  ) {}

  ngOnInit(): void {
    this.post = this._data?.post;
    this.time = moment(this._data?.time).format("HH:mm");
    this.taskStart = moment(this.post?.taskStart).format("HH:mm");
    this.taskEnd = moment(this.post?.taskEnd).format("HH:mm");

    this.check_outTime = new FormControl(this.time, {
      validators:
        this.post?.taskStart && !this.post?.taskEnd
          ? this.checkInValdator()
          : this.post?.taskEnd
          ? this.checkOutValidator()
          : null,
    });
  }

  ngAfterViewInit() {
    this.endTimeToggleTimepicker.open();
  }

  update() {
    if (this.check_outTime.valid) {
      const currentDate = moment();
      const combinedDateTime = moment(
        `${currentDate.format("YYYY-MM-DD")} ${this.check_outTime.value}`,
        "YYYY-MM-DD HH:mm"
      );
      const formattedDateTime = combinedDateTime.format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      this.loadingProgress = true;
      const taskData = {
        doc: {
          clinic_id: this.post.clinicId,
          ...(this._data.startOrEnd === "task_start" && {
            task_end: this.post.taskEnd,
          }),
          ...(this._data.startOrEnd === "task_end" && {
            task_start: this.post.taskStart,
          }),
          [this._data.startOrEnd]: formattedDateTime,
        },
      };
      this.taskDataService
        .updateTask(taskData, this.post.id, "time_tracker")
        .subscribe(
          (data) => {
            this.toast.success("", "Thread Updated");
            this.threadEntity.updateOneInCache({
              ...data,
              id: data.id,
              modified: true,
            });
            this.loadingProgress = false;
            this.matDialogRef.close("Task Updated");
          },
          (error) => {
            this.loadingProgress = false;
            this.toast.error(error.error, "Error");
          }
        );
    } else {
      this.check_outTime.markAsTouched();
    }
  }

  getStyle(post: Thread): string {
    if (post?.docType !== "time_tracker") return "";
    return post.taskStart && !post.taskEnd
      ? "#AAECC5"
      : post.taskStart && post.taskEnd
      ? "#DCABAB"
      : "";
  }

  checkInValdator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedTime = control.value;
      const endMinutes = this.convertToMinutes(selectedTime);
      const now = moment();
      const currentMinutes = now.hours() * 60 + now.minutes();
      if (endMinutes === currentMinutes) {
        this.checkCurrentTime(control);
        return { timeError: true };
      }
      return null;
    };
  }

  checkOutValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedTime = control.value;
      const startTime = moment(this._data?.CheckInTime).format("HH:mm");
      const startMinutes = this.convertToMinutes(startTime);
      const endMinutes = this.convertToMinutes(selectedTime);
      const now = moment();
      const currentMinutes = now.hours() * 60 + now.minutes();
      if (endMinutes === currentMinutes) {
        this.checkCurrentTime(control);
        return { timeError: true };
      }
      if (this.post?.taskEnd) {
        if (startMinutes >= endMinutes) {
          return { timeRangeError: true };
        }
      }
      return null;
    };
  }
  checkCurrentTime(control: AbstractControl) {
    let previousMinute = moment().minutes();
    this.intervalId = setInterval(() => {
      const now = moment();
      const currentMinute = now.minutes();
      if (currentMinute !== previousMinute) {
        control?.updateValueAndValidity();
        previousMinute = currentMinute;
      }
    }, 1000);
  }

  convertToMinutes(time: string): number {
    const [hours, minutes] = time.split(":").map((part) => parseInt(part, 10));
    return hours * 60 + minutes;
  }
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
