import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { RestService } from '../../service/api/rest.service';
import { SystemUsers } from './systemUsers.model';
import { ReplaySubject } from 'rxjs';
import { User } from 'app/types';

@Injectable({ providedIn: "root" })
export class SystemUsersEntityService extends EntityCollectionServiceBase<SystemUsers> {
  private _currentUser: User | undefined = undefined;

  /* reason behind ReplySubject is that, by this user will not have to
        get the missed user by directly accessing `currentUser`.
    // It assumes that after the user logs in, the `currentUser` will be set.
    // And also that if the user logs out, page will reload.
   */
  public readonly currentUser$: ReplaySubject<User> =
    new ReplaySubject<User>();

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private rest: RestService
  ) {
    super("SystemUsers", serviceElementsFactory);
  }

  get currentUser(): User {
    // Return a copy of _currentUser
    return Object.assign({}, this._currentUser) as User;
  }

  set currentUser(User: User) {
    this._currentUser = User;
    this.currentUser$.next(User);
  }
}
