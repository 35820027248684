<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="accent m-0" fxFlex="1 0 auto" fxLayout="row">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center">
      </div>
      <div fxLayout="column" class="py-8" fxLayoutAlign="center center">
        <div class="card-breadcrumb px-24" fxLayout="row" fxLayoutAlign="start center" fxLayout.xs="column">
          <p>{{post.clinicName}}</p>
        </div>
      </div>
      <div>
        <button mat-icon-button type="submit" aria-label="Save Group">
          <mat-icon matTooltip="{{ 'THREAD.save' | translate }}">save</mat-icon>
        </button>

        <button mat-icon-button aria-label="Close Dialog" (click)="matDialogRef.close()">
          <mat-icon matTooltip="{{ 'THREAD.close' | translate }}">close</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
  <div class="loading-div" *ngIf="loadingProgress">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
  <div mat-dialog-content class="py-24 px-0 m-0 main-content">
    <div class="sections">
      <div class="section" fxLayout="column" fxLayout.gt-xs="row">
        <div fxFlex class="labels">
          <div fxLayout="row" class="preheader" fxLayoutAlign="space-between start"
            [ngStyle]="{'background-color' : getStyle(post)}">
            <div class="user" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="column">
                <div class="time"><span class="cursor-pointer ">{{post?.clinicName}}
                  </span></div>
                <span class="time post-title">{{post?.categoryName}}</span>
              </div>
            </div>
          </div>
          <header fxLayout="row" fxLayoutAlign="space-between start" class="updateddesign">
            <div class="user " fxLayout="row" fxLayoutAlign="start center">
              <profile-image [userProfile]="post?.docCreator?.id" class="avatar cursor-pointer"
                [src]="post?.docCreator?.file?.thumb_url"></profile-image>

              <div fxLayout="column" class="ml-12">
                <div class="title">
                  <span [userProfile]="post.docCreator?.id"
                    class="username cursor-pointer">{{post?.docCreator?.full_name
                    ? post?.docCreator?.full_name : '' }}</span>
                </div>
                <span class="time" [ngStyle]="{'font-weight': 400}">{{post?.createdTime | amTimeAgoCustom
                  | async}} <span *ngIf="post?.taskStart && !post?.taskEnd">{{ 'THREAD.check_in' | translate }}</span>
                  <span *ngIf="post?.taskEnd">{{ 'THREAD.check_out' | translate }}</span></span>
              </div>
            </div>
            <div fxLayout="row">
              <ng-container>
                <span class="checkTime">{{time}}</span>
              </ng-container>
            </div>
          </header>
          <div class="section-content">
            <mat-form-field appearance="outline" class="w-100-p end-time">
              <mat-label *ngIf="post?.taskStart && !post?.taskEnd">Check in</mat-label>
              <mat-label *ngIf="post?.taskEnd">Check out</mat-label>
              <input [formControl]="check_outTime" matInput [format]="24" [ngxTimepicker]="endTimeToggleTimepicker"
                readonly />
              <!-- <ngx-material-timepicker-toggle matSuffix
                [for]="endTimeToggleTimepicker"></ngx-material-timepicker-toggle> -->
              <ngx-material-timepicker #endTimeToggleTimepicker [preventOverlayClick]="true"></ngx-material-timepicker>
              <mat-error *ngIf="check_outTime.hasError('timeRangeError')">{{ 'THREAD.checkout_TimeRangeError' | translate}}</mat-error>
              <mat-error *ngIf="check_outTime.hasError('timeError')">{{ 'THREAD.checkInOut_timeError' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="m-12" fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button color="accent" type="submit" class="update-button" (click)="update()">
      {{ 'THREAD.update' | translate }}
    </button>
  </div>
</div>