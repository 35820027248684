import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from "@ngrx/data";
import { Injectable } from "@angular/core";
import { IGroup } from "./group.model";
import { RestService } from "../../service/api/rest.service";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class GroupsEntityService extends EntityCollectionServiceBase<IGroup> {
  private _currentGroup: IGroup | undefined = undefined;
  public readonly currentGroup$: Subject<IGroup> = new Subject<IGroup>();

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private rest: RestService
  ) {
    super("Group", serviceElementsFactory);
  }

  get currentGroup(): IGroup {
    // Return a copy of _currentGroup
    return Object.assign({}, this._currentGroup) as IGroup;
  }

  set currentGroup(group: IGroup) {
    this._currentGroup = group;
    this.currentGroup$.next(group);
  }
}
