import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NewAuthDataService } from './api/newAuth-data.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate
{
    constructor(private router: Router, private auth: NewAuthDataService)
    {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        if ( !this.auth.isActive() )
        {

            if ( state.url === '/profile/security' )
            {
                this.router.navigate(['/auth'], { state: { data: '/profile/security'} });
            } else
            {
                this.router.navigate(['auth']);
            }

            return false;
        }
        return true;
    }

}
