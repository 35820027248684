import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseUtils } from '@fuse/utils';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupsEntityService } from '../../../../../store/group/groups-entity.service';
import { IGroup } from '../../../../../store/group/group.model';
import { ToastrService } from 'ngx-toastr';

import { GroupsDataService } from '../../../../../store/group/groups-data.service';
import { FileUploadService } from 'app/service/api/file-upload.service';
import { CategoriesListComponent } from './categories-list/categories-list.component';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';

@Component({
    selector     : 'app-create-group',
    templateUrl  : './group.component.html',
    styleUrls    : ['./group.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GroupDialogComponent implements OnInit, OnDestroy
{
    list: any;
    group: IGroup |null;
    dialogTitle: string;
    loadingProgress = false;
    componentActive = true;
    groupForm: FormGroup;
    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};

    toggleInArray = FuseUtils.toggleInArray;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @ViewChild('checklistMenuTrigger')
    checklistMenu: MatMenuTrigger;

    @ViewChild('newCheckListTitleField')
    newCheckListTitleField;

    @ViewChild(CategoriesListComponent, {static: false}) clist: CategoriesListComponent;

    categoriesFormArray: FormArray;


    // Private
    private _unsubscribeAll: Subject<any>;


    constructor(
        public matDialogRef: MatDialogRef<GroupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _matDialog: MatDialog,
        private store: Store<any>,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private groupService: GroupsEntityService,
        private groupDataService: GroupsDataService,
        private toast: ToastrService,
        private fileUploadService: FileUploadService,
        private newAuthdataService: NewAuthDataService,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.categoriesFormArray = new FormArray([]);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Define the form group
        this.groupForm = this._formBuilder.group({
            name            : ['', [Validators.required,Validators.maxLength(25)]],
            description     : ['',Validators.maxLength(70)],
            isPublic        : [false, Validators.required],
            invitationSentBy: ['Admin', Validators.required],
            approvedBy      : ['Admin', Validators.required],
            attachment      : [null],
            imgName         : [''],
            imgSrc          : [''],
            imgUrl          : [''],
            imgType         : [''],
            categories      : this._formBuilder.array([], Validators.compose([this.customValidateArray()]))
        });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        this.componentActive = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    async saveGroup(): Promise<any>
    {
        if ( this.groupForm.valid )
        {
            // if (this.groupForm.dirty) {
            // Copy over all of the original product properties
            // Then copy over the values from the form
            // This ensures values not on the form, such as the Id, are retained

            this.loadingProgress = true;
            const group = {...this.groupForm.value};
            let g: any = {...this.group};

            g = {...g, ...group};

            if ( group.attachment && !group.attachment?.signed_id )
            {
                const file = await this.fileUploadService.uploadDirectFile(group.attachment, 0, 1);
                delete g.attachment;
                g = {...g, ...file};
            }
            const tempCategories: any = [];
            const trueCategories = this.clist.categoriesList.filter((data: any) => data.checked === true);
            for (let i = 0; i < trueCategories.length; i++)
            {
                if ( this.clist.categoriesList[i].checked )
                {
                    tempCategories.push({
                        name      : this.clist.categoriesList[i].name,
                        sort_order: i + 1
                    });
                }
            }
            g.categories = tempCategories;
            this.groupService.add(g).subscribe((res) => {
                    this.loadingProgress = false;
                    this.groupDataService.getInGroupsPageSubject().next(res);
                    this.matDialogRef.close();
                    this.groupService.addOneToCache(res);
                    this.newAuthdataService.getApprovedGroups(1, true).subscribe();
                    this.toast.success('Group created successfully!');
                },
                (error) => {
                    this.loadingProgress = false;
                    this.toast.error(error.error.errors.join(', '));
                });

        }
    }

    displayGroup(group: IGroup | null): void
    {
        // Set the local product property
        this.group = group;
        if ( this.group )
        {
            // Reset the form back to pristine
            this.groupForm.reset();

            // Display the appropriate page title
            if ( this.group.id === 0 )
            {
                this.dialogTitle = 'Create';
            } else
            {
                this.dialogTitle = `Edit: ${this.group.name}`;
            }
            // Update the data on the form
            this.groupForm.patchValue({
                name            : this.group.name,
                description     : this.group.description,
                isPublic        : this.group.isPublic,
                approvedBy      : this.group.approvedBy,
                invitationSentBy: this.group.invitationSentBy,
            });
            // Patch all requirements.
            // group.categories.forEach((category) => {
            //     // Create a formGroup that we will patch data to.
            //     const reqFormGroup = this.initGroupCategory();
            //     // Patch our value to the formGroup
            //     reqFormGroup.patchValue(category);
            //     // Push our patched formGroup to our formArray
            //     // this.categoriesFormArray.push(reqFormGroup);
            //     this.t.push(reqFormGroup);
            // });
        }
    }

    async onFileChange(event): Promise<any>
    {
        if ( event.target.files && event.target.files[0] )
        {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // reader.readAsArrayBuffer(file);
            reader.addEventListener(
                'load',
                async () => {
                    file.src = reader.result;
                    this.groupForm.get('attachment')?.setValue(file, {emitModelToViewChange: false});
                },
                false
            );
        }
    }


    removeAttachment(): void
    {
        this.groupForm.get('attachment')?.reset();
    }

    customValidateArray(): ValidatorFn
    {
        return (formArray: FormArray): { [key: string]: any } | null => {
            let valid = true;
            const categoryNames: string[] = [];
            formArray.controls.forEach((x: FormControl, index) => {
                if ( categoryNames.length === 0 )
                {
                    categoryNames.push(x.value.categoryName.trim());
                } else
                {
                    if ( categoryNames.indexOf(x.value.categoryName.trim()) === -1 )
                    {
                        categoryNames.push(x.value.categoryName.trim());
                    } else
                    {
                        valid = false;
                        (formArray.controls[index] as FormGroup).controls.categoryName.setErrors({
                            Matched: true
                        });
                    }
                }
            });
            return valid ? null : {error: 'Categories must have unique names.'};
        };
    }

    groupStatus(groupStatus: boolean): void
    {
        this.groupForm.patchValue({
            isPublic: groupStatus
        });
    }

    emailStatus(emailStatus: any): void
    {
        this.groupForm.patchValue({
            invitationSentBy: emailStatus
        });
    }

    groupApproveStatus(groupApproveStatus: any): void
    {
        this.groupForm.patchValue({
            approvedBy: groupApproveStatus
        });
    }

    groupVisibilityStatus(groupVisibilityStatus: any): void
    {
        this.groupForm.patchValue({
            isPublic: groupVisibilityStatus
        });
    }

}
