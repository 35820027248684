import { ErrorHandler, NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../service/auth.guard';
import { GroupDialogComponent } from './components/dialogs/group/group.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ThreadDialogComponent } from './components/dialogs/thread/thread.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { MomentModule } from 'ngx-moment';
import { ToastrModule } from 'ngx-toastr';
import { TaskComponent } from './components/dialogs/task/task.component';
import { MatSelectModule } from '@angular/material/select';
import { ThreadconfirmationComponent } from './components/dialogs/threadconfirmation/threadconfirmation.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AttachmentsModule } from './components/attachments/attachments.module';
import { CategoriesListComponent } from './components/dialogs/group/categories-list/categories-list.component';
import { UtilModule } from './components/util/util.module';
import { GalleryModule } from './components/gallery/gallery.module';
import { TaskCommentFilesComponent } from './components/dialogs/task/task-comment-files/task-comment-files.component';
import { CommentsModule } from './components/comments/comments.module';
import { PostModule } from './components/post/post.module';
import { CommonDirectiveModule } from '../../directive/common-directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalErrorHandler } from '../../service/global-error-handler.service';
import { BrowserCompatibleGuard } from '../../service/browser-compatible.guard';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RichTextFieldModule } from './components/rich-text-field/rich-text-field.module';
import { ShareThreadDialogComponent } from './components/dialogs/share-thread-dialog/share-thread-dialog.component';
import { ShareThreadModule } from './components/dialogs/share-thread-dialog/share-thread.module';
import { DownloadQrCodeComponent } from './components/dialogs/download-qr-code/download-qr-code.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


const routes: Routes = [
    {
        path        : '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate : [AuthGuard]
    }, {
        path        : 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate : [AuthGuard]
    }, {
        path        : 'messages',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
        canActivate : [AuthGuard]
    }, {
        path        : 'backoffice',
        loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule),
        canActivate : [AuthGuard]
    }, {
        path        : 'groups',
        loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
    }, {
        path        : 'auth',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
        canActivate : [BrowserCompatibleGuard]
    }, {
        path        : 'error',
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
    }, {
        path        : 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        canActivate : [AuthGuard]
    }, {
        path        : 'contacts',
        loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule),
        canActivate : [AuthGuard]
    }, {
        path        : 'search/:searchInput',
        loadChildren: () => import('./searchPage/search.module').then(m => m.SearchModule),
        canActivate : [AuthGuard]
    },
    {
        path        : 'terms-and-conditions',
    loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
    },
    // {
    //     path        : 'privacy-policy',
    // loadChildren: () => import('./privacy-policy/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
    // },
    
    {
        path        : 'slide-show',
        loadChildren: () => import('./slide-show/slide-show.module').then(m => m.SlideShowModule),
    }, {
        path        : 'landing',
        loadChildren: () => import('./groups/group/landing-page/landing-page.module').then(m => m.LandingPageModule),
    },
];

@NgModule({
    providers: [{provide: ErrorHandler, useClass: GlobalErrorHandler}],

    declarations: [
        ThreadDialogComponent,
        TaskComponent,
        ThreadconfirmationComponent,
        GroupDialogComponent,
        CategoriesListComponent,
        TaskCommentFilesComponent,
        DownloadQrCodeComponent
    ],

    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,

        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatSnackBarModule,
        DashboardModule,
        MomentModule,
        ToastrModule.forRoot(),
        MatSelectModule,
        InfiniteScrollModule,
        AttachmentsModule,
        UtilModule,
        GalleryModule,
        CommentsModule,
        ShareThreadModule,
        PostModule,
        CommonDirectiveModule,
        TranslateModule,
        NgxMaterialTimepickerModule,
        RichTextFieldModule
    ],
    entryComponents: [GroupDialogComponent]
})
export class AppsModule
{
}
