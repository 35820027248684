import { Injectable, Injector } from '@angular/core';
import { LocalStorage, SessionStorage } from 'angular-web-storage';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import * as ActionCable from 'actioncable';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NewAuthDataService } from '../api/newAuth-data.service';
import { GroupQRCode } from 'app/store/group/group.model';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ActionCableService
{

    @LocalStorage('token')
    userToken;
    @SessionStorage('token')
    sessionToken;
    private statusSubject = new Subject<any>();
    private cable: ActionCable.Cable| null;
    private appChannelSet: boolean | null;
    notificationSubject = new Subject<any>();
    private clinicUpdate = new Subject<any>();
    private appearanceUpdate = new Subject<any>();
    private patientCallerUpdate = new Subject<any>();
    private slideUpdate = new Subject<any>();
    private userUpdate = new Subject<any>();
    private threadUpdate = new Subject<any>();
    private docReadUpdate = new Subject<any>();
    private qrCodeUpdate = new Subject<any>();

    private appearanceChannel: ActionCable.Channel & { received: (obj: any) => void }| any;
    private notificationChannel: ActionCable.Channel & { received: (obj: any) => void }| any;
    private clinicChannel: ActionCable.Channel & { received: (obj: any) => void } | any;
    private patientCallerChannel: ActionCable.Channel & { received: (obj: any) => void } | any;
    private slideCallerChannel: ActionCable.Channel & { received: (obj: any) => void } | any;
    private threadChannel: ActionCable.Channel & { received: (obj: any) => void } | any;
    private commentsReadChannel: ActionCable.Channel & { received: (obj: any) => void } | any;
    private userProfileUpdateChannel: ActionCable.Channel & { received: (obj: any) => void } | any;

    // emits when QR Code is rotated for the group
    private qrCodeRotateChannel?: ActionCable.Channel & {
      received: (obj: { qr_code: GroupQRCode }) => void
    };

    constructor( private toast: ToastrService, private  injector: Injector, private readonly location: Location)
    {
    }

    private getCable(verifyToken = false): ActionCable.Cable
    {
        if ( this.cable )
        {
            return this.cable;
        }
        if ( this.userToken || this.sessionToken || verifyToken )
        {
            let cableToken = '';
            if (this.userToken)
            {
                cableToken = `?token=${this.userToken}`;
            }else if (this.sessionToken)
            {
                cableToken = `?token=${this.sessionToken}`;
            }
            this.appearanceChannel = this.notificationChannel = this.clinicChannel = null;
            this.cable = ActionCable.createConsumer(`${environment.BASE_WS_URL}${cableToken}`);
            return this.cable;
        } else
        {
            throw new Error('User is not sign in');
        }
    }

    reset(): void
    {
        this.appearanceChannel?.unsubscribe();
        this.notificationChannel?.unsubscribe();
        this.clinicChannel?.unsubscribe();
        this.cable?.disconnect();
        this.cable = null;
    }

    getChatUpdates(roomID): any
    {
        const subject = new Subject();
        const chatChannel = this.getCable().subscriptions.create({
                channel: 'ChatChannel',
                room_id: roomID
            },
            {
                connected(): any
                {
                },
                disconnected(): any
                {
                },
                received: (obj: any): any => {
                    subject.next(obj);
                }
            }
        );
        return {observer: subject.asObservable(), chatChannel};
    }

    getNotificationsUpdate(): Observable<any>
    {
        if ( !this.notificationChannel )
        {
            this.notificationChannel = this.getCable().subscriptions.create('NotificationChannel', {
                received: (obj: any): void => {
                    this.notificationSubject.next(obj);
                    this.showNotifications(obj);
                }
            });
        }
        return this.notificationSubject.asObservable();
    }

    getStatusUpdate(): Observable<any>
    {
        if ( !this.appChannelSet )
        {
            this.appChannelSet = true;
            this.getCable().subscriptions.create('AppearanceChannel', {
                unsubscribe: () => {
                    this.appChannelSet = null;
                },
                received   : (obj: any): void => {
                    this.statusSubject.next(obj);
                }
            });
        }
        return this.statusSubject
            .asObservable();
    }

    getClinicUpdate(): Observable<any>
    {
        if ( !this.clinicChannel )
        {
            this.clinicChannel = this.getCable().subscriptions.create('ClinicChannel', {
                unsubscribe: () => {
                    this.clinicChannel = null;
                },
                received   : (obj: any): void => {
                    this.clinicUpdate.next(obj);
                }
            });
        }
        return this.clinicUpdate.asObservable();
    }

    getUserAppearances(): Observable<any>
    {
        if ( !this.appearanceChannel )
        {
            this.appearanceChannel = this.getCable().subscriptions.create('AppearanceChannel', {
                unsubscribe: () => {
                    this.appearanceChannel = null;
                },
                connected  : () => {
                    this.appearanceChannel?.send({
                        online_status: 'online',
                        action       : 'update'
                    });
                },
                received   : (obj: any): void => {
                    this.appearanceUpdate.next(obj);
                },

            });
        }
        return this.appearanceUpdate.asObservable();
    }
    getAppearanceChannel(): ActionCable.Channel
    {
        return this.appearanceChannel;
    }

    getPatientCallerUpdates(): Observable<any>
    {
        if ( !this.patientCallerChannel )
        {
            this.patientCallerChannel = this.getCable(true).subscriptions.create('PatientChannel', {
                unsubscribe: () => {
                    this.patientCallerChannel = null;
                },
                received   : (obj: any): void => {
                    this.patientCallerUpdate.next(obj);
                },
            });
        }
        return this.patientCallerUpdate.asObservable();
    }

    getSlideUpdates(clinicId): Observable<any>
    {
        if ( !this.slideCallerChannel )
        {
            this.slideCallerChannel = this.getCable( true).subscriptions.create({
                channel  : 'SlideShowChannel',
                clinic_id: clinicId
            }, {
                unsubscribe: () => {
                    this.slideCallerChannel = null;
                },
                received   : (obj: any): void => {
                    this.slideUpdate.next(obj);
                },
            });
        }
        return this.slideUpdate.asObservable();
    }
    getUserProfileUpdate(): Observable<any>
    {
        if ( !this.userProfileUpdateChannel )
        {
            this.userProfileUpdateChannel = this.getCable(true).subscriptions.create('UserProfileChannel', {
                unsubscribe: () => {
                    this.userProfileUpdateChannel = null;
                },
                received   : (obj: any): void => {
                    this.userUpdate.next(obj);
                }
            });
        }
        return this.userUpdate.asObservable();
    }
    getThreadUpdate(threadId): Observable<any>
    {
        if ( !this.threadChannel )
        {
            this.threadChannel = this.getCable( true).subscriptions.create({
                channel  : 'ShareThreadChannel',
                doc_id: threadId
            }, {
                unsubscribe: () => {
                    this.threadChannel = null;
                },
                received   : (obj: any): void => {
                    this.threadUpdate.next(obj);
                },
            });
        }
        return this.threadUpdate.asObservable();
    }
    getDocReadChannel(): any
    {
        if ( !this.commentsReadChannel )
        {
            this.commentsReadChannel = this.getCable(true).subscriptions.create('DocReadChannel', {
                unsubscribe: () => {
                    this.commentsReadChannel = null;
                },
                received   : (obj: any): void => {
                    this.docReadUpdate.next(obj);
                },
            });
        }
        return this.docReadUpdate.asObservable();
    }

    showNotifications(notificationObj): void
    {
        const user = this.injector.get(NewAuthDataService).getSignedInUser();
        const currentRoute = this.location.path().trim();
        if ( !('Notification' in window) )
        {
            // Check if the browser supports notifications
            this.toast.error('This browser does not support desktop notification');
        } else if ( Notification.permission === 'granted' )
        {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            let notificationContent = {};

            switch (notificationObj.type)
            {
                case 'thread_notification':
                    if ( +notificationObj.doc.doc.doc_creator_id !== +user.id && notificationObj.url !== currentRoute && notificationObj.status === 'created'  )
                    {
                        notificationContent = {
                            body              : `New Thread Created in Clinic ${notificationObj.doc.doc.clinic_name}`,
                            icon              : '/assets/images/logos/doc_logo.png',
                            requireInteraction: false
                        };

                        const threadotification = new Notification('OnlineDoc', notificationContent);
                        threadotification.onclick = (event) => {
                            event.preventDefault(); // prevent the browser from focusing the Notification's tab
                            window.open(`${environment.BASE_WEB_URL}${notificationObj.url}`, '_blank');
                        };
                    }
                    break;
                case 'task_notification':
                    if ( +notificationObj.doc.doc.doc_creator_id !== +user.id && currentRoute !== notificationObj.url)
                    {
                        notificationContent = {
                            body              : `New Task Created in Clinic ${notificationObj.doc.doc.clinic_name}`,
                            icon              : '/assets/images/logos/doc_logo.png',
                            requireInteraction: false
                        };

                        const taskNotification = new Notification('OnlineDoc', notificationContent);
                        taskNotification.onclick = (event) => {
                            event.preventDefault(); // prevent the browser from focusing the Notification's tab
                            window.open(`${environment.BASE_WEB_URL}${notificationObj.url}`, 'blank');
                        };
                    }
                    break;
                case 'group_join_notification':
                    if ( currentRoute !== notificationObj.url )
                    {
                        notificationContent = {
                            body              : `${notificationObj.notification.notification.full_name} want to join ${notificationObj.notification.notification.clinic_name}`,
                            icon              : '/assets/images/logos/doc_logo.png',
                            requireInteraction: false
                        };

                        const groupJoinNotification = new Notification('OnlineDoc', notificationContent);
                        groupJoinNotification.onclick = (event) => {
                            event.preventDefault(); // prevent the browser from focusing the Notification's tab
                            window.open(`${environment.BASE_WEB_URL}${notificationObj.url}`, 'blank');
                        };
                    }
                    break;
                case 'join_request_update_notification':
                    if ( currentRoute !== notificationObj.url )
                    {
                        notificationContent = {
                            body              : `Your Request to ${notificationObj.doc.doc.clinic_name} has been  ${notificationObj.doc.doc.activity_type === 'clinic_join_request_rejected' ? 'Rejected' : 'Accepted'}`,
                            icon              : '/assets/images/logos/doc_logo.png',
                            requireInteraction: false
                        };
                        const groupUpdateNotification = new Notification('OnlineDoc', notificationContent);
                        groupUpdateNotification.onclick = (event) => {
                            event.preventDefault(); // prevent the browser from focusing the Notification's tab
                            window.open(`${environment.BASE_WEB_URL}${notificationObj.url}`, 'blank');
                        };
                    }
                    break;
                case 'chat_room':
                    if ( +notificationObj.notification_id === +user.id && currentRoute !== notificationObj.url && notificationObj.activity === 'new_message' )
                    {
                        notificationContent = {
                            body              : `You Received a New message from ${notificationObj.chat_room.user.full_name}`,
                            icon              : '/assets/images/logos/doc_logo.png',
                            requireInteraction: false
                        };
                        const chatNotification = new Notification('OnlineDoc', notificationContent);
                        chatNotification.onclick = (event) => {
                            event.preventDefault(); // prevent the browser from focusing the Notification's tab
                            window.open(`${environment.BASE_WEB_URL}${notificationObj.url}`, 'blank');
                        };
                    }
                    break;
            }

        } else if ( Notification.permission !== 'denied' )
        {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if ( permission === 'granted' )
                {
                    // const notification = new Notification('Hi here!');
                    // …
                }
            });
        }
    }
    
    getQRCodeRotate(
        clinicId: number,
      ): Observable<GroupQRCode> {
        return this.getClinicUpdate().pipe(
          filter(
            (next: { qr_code: GroupQRCode; type: "qr_code" }) =>
              next.type === "qr_code" && next.qr_code?.clinic_id === clinicId
          ),
          map((i) => i.qr_code)
        );
    }      
}
