import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Subject } from "rxjs";

import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ThreadconfirmationComponent } from "../threadconfirmation/threadconfirmation.component";
import { NewAuthDataService } from "../../../../../service/api/newAuth-data.service";
import { GroupsEntityService } from "../../../../../store/group/groups-entity.service";
import { ThreadsDataService } from "../../../../../store/thread/threads-data.service";
import { ThreadsEntityService } from "../../../../../store/thread/threads-entity.service";
import { LocalStorageService } from "angular-web-storage";
import { AttachmentsComponent } from "../../attachments/attachments.component";
import { takeUntil } from "rxjs/operators";
import { User } from "../../../../../types";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { UploadingFilesEntityService } from "../../../../../store/uploading-files/uploading-files-entity.service";
import { UpdateCategoryDialogComponent } from "../../../groups/group/sidenavs/settings/categories/update-category-dialog/update-category-dialog.component";
import { IGroup } from "../../../../../store/group/group.model";
import { GroupsDataService } from "../../../../../store/group/groups-data.service";
import { Thread } from "../../../../../store/thread/thread.model";
import { UploadingFilesDataService } from "../../../../../store/uploading-files/uploading-files-data.service";

@Component({
  selector: "thread-dialog",
  templateUrl: "./thread.component.html",
  styleUrls: ["./thread.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ThreadDialogComponent implements OnInit, OnDestroy ,AfterViewInit{
  prioritycheck: boolean;
  confirmation: boolean;
  counter = 0;
  list: any;
  priorities: any;
  community = false;
  userData: User;
  threadData: Thread;
  dialogTitle: string;
  componentActive = true;
  threadForm: FormGroup;
  currentSelectedGroup: any;
  currentSelectedCategory: any;
  groupsList: any[];
  dialogOpenStatus: boolean;
  newThread: boolean;
  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  // Private
  private _unsubscribeAll: Subject<any>;
  @ViewChild("attachmentsC", { static: false })
  attachmentsC: AttachmentsComponent;
  removedFiles: any = [];
  allowGroupEdit = true;
  loadingProgress = false;
  groupsDataList: any;
  prioritiesList = true;
  saveButton = false;
  currentClinic: IGroup | any;
  allowLargeFiles: boolean;
  isSharePost: boolean;
  sharedClinicId: number | undefined;
  contact: any;

  constructor(
    private toast: ToastrService,
    public matDialogRef: MatDialogRef<ThreadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _matDialog: MatDialog,
    private _authService: NewAuthDataService,
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private progressBarService: FuseProgressBarService,
    private _groupService: GroupsEntityService,
    private groupDataService: GroupsDataService,
    private threadDataService: ThreadsDataService,
    private threadEntity: ThreadsEntityService,
    private uploadingFilesEntityService: UploadingFilesEntityService,
    private fileUploadingDataService: UploadingFilesDataService,
    private local: LocalStorageService
  ) {
    // Set the private defaults
    this.matDialogRef.disableClose = true;
    this._unsubscribeAll = new Subject();
    this._authService
      .getUser()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => {
        this.userData = user;
      });
    this.priorities = [
      {
        name: "important",
        color: "blue-600",
      },
      {
        name: "critical",
        color: "blue-600",
      },
      {
        name: "notice",
        color: "blue-600",
      },
      {
        name: "normal",
        color: "blue-600",
      },
    ];
    this.dialogOpenStatus = false;
    if (this._data.notAllowGroupEdit) {
      this.allowGroupEdit = false;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On initz
   */
  ngOnInit(): void {
    this.contact = this._data.contact;
    this.counter = this._data.counter;

    this.sharedClinicId = this._data?.sharedClinicId;
    if (this._data?.share) {
      this.isSharePost = this._data?.share;
    } else {
      this.isSharePost = false;
    }
    this.currentClinic = this._data.currentClinic ?? undefined;
    this.dialogOpenStatus = false;
    this.newThread = this._data.new;
    this.allowLargeFiles =
      this.currentClinic?.clinicModules?.large_file_size ?? false;
    this._authService.getUser().subscribe((user) => {
      this.userData = user;
    });
    // Define the form thread
    this.threadForm = this._formBuilder.group({
      priority: [""],
      title: [""],
      description: [""],
      groupId: [""],
      categoryId: [""],
      attachment: [""],
    });
    
    this.currentClinic?.isDefault
      ? (this.prioritiesList = false)
      : (this.prioritiesList = true);
    this.threadForm.reset();
    this.userClinicWithCategories();
    this.confirmation = this.local.get("displayThreadConfirmation")
      ? this.local.get("displayThreadConfirmation").status
      : true;

      if (this._data?.textAreaData) {
       const formattedtext = this._data?.textAreaData.replace(/\n/g, '<br>')
        this.threadForm.patchValue({
          description:formattedtext
        })
      }
  }

  ngAfterViewInit(): void { 
    if (this.newThread) {
      this.attachmentsC.pickFile();
    }
  } 

  userClinicWithCategories(): void {
    this.threadDataService.getUserClinicsWithCategories().subscribe((data) => {
      this.groupsList = data.clinics;
      const currentClinic = this.groupsList.find(
        (group) => group.id === this._data.currentClinic?.id
      );
      this.currentSelectedGroup = currentClinic;
      if (this._data.categoryId) {
        const selectedCategory = currentClinic.clinic_categories.find(
          (category) => category.id === this._data.categoryId
        );
        this.currentSelectedCategory = {
          id: selectedCategory?.id,
          name: selectedCategory?.name,
          clinicContact: selectedCategory?.clinic_contact,
        };
        this.allowGroupEdit = false;
      } else {
        this.subscribedThreadData(data);
      }
    });
  }

  subscribedThreadData(data): void {
    this.groupsDataList = data;
    if (!this.newThread) {
      this.groupsDataList.clinics.forEach((ele) => {
        if (this._data.threadData.clinicId === ele.id) {
          ele.is_default
            ? (this.prioritiesList = false)
            : (this.prioritiesList = true);
        }
      });
      this.allowGroupEdit = false;
      this.confirmation = false;
      this.threadData = { ...this._data.threadData };
      if (this._data.share) {
        const groupsharewithCategory = this.groupsDataList.clinics.find(
          (clinic) => clinic.id === this._data.clinicId
        );
        this.threadData = {
          ...this.threadData,
          clinicCategoryId: groupsharewithCategory?.clinic_categories[0].id,
        };
      }
      let description = this.threadData.description;
      const formattedDescription = description?.replace(/\n/g, '<br>')
      this.threadForm.patchValue({
        priority: this.threadData.priority,
        title: this.threadData.title,
        description: formattedDescription,
        groupId: this.threadData.clinicId,
        categoryId: this.threadData.clinicCategoryId,
      });
      this.currentSelectedGroup = {
        id: this.threadData.clinicId,
        name: this.threadData.clinicName,
      };
      this.currentSelectedCategory = {
        id: this.threadData.clinicCategoryId,
        name: this.threadData.categoryName,
      };
      this.currentSelectedGroup = this.groupsDataList.clinics.find(
        (group) => group.id === this.currentSelectedGroup.id
      );
      // this.currentSelectedCategory.push(this.currentSelectedGroup.clinic_categories);
      this.allowLargeFiles = this.currentSelectedGroup?.allow_large_files;
    } else {
      if (this.currentClinic?.id) {
        this.currentSelectedGroup = this.groupsList
          .filter((c) => +c.id === +this.currentClinic?.id)
          .pop();
        this.currentSelectedCategory =
          this.currentSelectedGroup.clinic_categories
            .filter(({ name }) => name === "Standard (Default)")
            .pop();
      }
    }
  }

  async sharePost(): Promise<void> {
    if (this.threadForm.valid) {
      this.progressBarService.show();
      this.threadForm.controls.categoryId.setValue(
        this.currentSelectedCategory?.id
      );
      const g = { ...this.threadForm.value };
      // let sharedFile = this._data.threadData.files?.map((ele) => {{src: ele?.src , filename: ele?.name}})
      const params = {
        clinic_id: this._data.clinicId,
        clinic_category_id: g.categoryId,
        doc_type: "thread",
        priority: g.priority,
        title: g.title,
        description: g.description,
        shared_files: this._data.threadData.files?.map((ele) => {
          return { src: ele?.src, name: ele?.name };
        }),
        sharing_from_clinic_id: this.sharedClinicId,
      };
      this.groupDataService.shareThread(params).subscribe(
        (data) => {
          this.groupDataService.setShareThreadId(data.id);
          if (this.attachmentsC.attachedCount > 0) {
            this.toast.info("Thread attachments are being shared!");
            this.uploadFilesToServer(data.id, data).then((res) => {
              this.saveButton = false;
              this.matDialogRef.close(["Thread Created"]);
            });
          } else {
            this.matDialogRef.close(["Thread Created"]);
          }
          this.matDialogRef.close(["close"]);
          this.progressBarService.hide();
          this.toast.success("Post Shared successfully");
        },
        (error) => {
          this.progressBarService.hide();
          this.toast.error(error.errors.join(", "), "Error");
        }
      );
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this.componentActive = false;
    this.dialogOpenStatus = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Remove attachment
   *
   * @param attachment
   */
  removeAttachment(attachment): void {
    this.removedFiles.push(attachment);
  }

  onCloseMenu(): void {
    if (this.currentSelectedGroup) {
      this.groupsDataList.clinics.forEach((ele) => {
        if (this.currentSelectedGroup.id === ele.id) {
          ele.is_default
            ? (this.prioritiesList = false)
            : (this.prioritiesList = true);
        }
      });
      this.currentSelectedCategory =
        this.currentSelectedGroup.clinic_categories.find(
          (res) => res.is_default
        );
    }
  }

  async uploadFilesToServer(threadId, threadData): Promise<void> {
    this.uploadingFilesEntityService.addOneToCache({
      threadId: threadId,
      id: threadId,
    });
    this.fileUploadingDataService.isFileUploading(threadId);
    let signedIdList = await this.attachmentsC.directUpload(
      threadData.clinicId
    );
    if (
      typeof signedIdList[0] === "string" &&
      signedIdList[0]?.includes("Error")
    ) {
      this.uploadingFilesEntityService.removeOneFromCache(threadId);
      this.threadEntity.updateOneInCache({
        isFileBeingUploaded: false,
        id: threadId,
      });
      this.toast.error("Clinic file uploading limit exceeded", "Error");
    } else {
      if (threadData.files) {
        signedIdList = [...signedIdList, ...threadData.files];
      }
      const data = {
        doc: {
          files: signedIdList.map((t) => t.signed_id),
        },
      };
      this.uploadingFilesEntityService.removeOneFromCache(threadId);
      this.threadDataService
        .updateThread(data, threadId)
        .toPromise()
        .then((resData) => {
          this.threadEntity.updateOneInCache({
            ...resData,
            isFileBeingUploaded: false,
            id: resData.id,
          });
          this.toast.success("Thread attachments successfully uploaded!");
        });
    }
  }

  saveThread(): void {
    const descriptionControl: FormControl = this.threadForm.get(
      "description"
    ) as FormControl;
    if (
      this.attachmentsC.attachedCount > 0 &&
      (descriptionControl.value === null || descriptionControl.value === "")
    ) {
      this.attachmentsC.attachedCount > 1
        ? descriptionControl.setValue(
            this.attachmentsC.attachments[0].file.name +
              " + " +
              (this.attachmentsC.attachedCount - 1)
          )
        : descriptionControl.setValue(
            this.attachmentsC.attachments[0].file.name
          );
    }
    const title = this.threadForm.get("title")?.value?.trim() || "";
    if (!descriptionControl.value) {
      this.toast.error("", "Description is required");
      return;
    }
    if (title === "" && descriptionControl.value) {
      this.threadForm.patchValue({
        title: descriptionControl.value.trim().split(" ").slice(0, 5).join(" "),
      });
    }
    if (this.currentSelectedCategory) {
      this.threadForm.controls.categoryId.setValue(
        this.currentSelectedCategory.id
      );
      this.threadForm.controls.groupId.setValue(this.currentSelectedGroup.id);
    }
    const g = { ...this.threadForm.value };
    g.doc_type = "thread";
    // tslint:disable-next-line:no-unused-expression
    this.prioritiesList ? g.priority : (g.priority = null);
    g.priority = g.priority === null ? g.priority : g.priority.toLowerCase();
    g.files = this.threadData?.files;

    if (!this.currentSelectedGroup?.id) {
      this.toast.error("Please select the group first.", "Group not selected!");
      return;
    }
    if (!this.currentSelectedCategory?.id) {
      this.toast.error(
        "Please select the group folder.",
        "Folder not selected!"
      );
      return;
    }
    if (this.newThread) {
      if (this.confirmation) {
        if (this.threadForm.valid) {
          this._matDialog
            .open(ThreadconfirmationComponent, {
              panelClass: "card-dialog",
              data: {
                from: "confirmation",
                group: this.currentSelectedGroup,
                category: this.currentSelectedCategory,
                threadData: g,
                newThread: this.newThread,
                threadID: this.threadData ? this.threadData.id : 0,
                confirmation: this.confirmation,
              },
            })
            .afterClosed()
            .subscribe((res) => {
              if (res) {
                switch (res) {
                  case "Change Group":
                    document.getElementById("groupNameMenu")?.click();
                    break;
                  case "Thread Created":
                    this.__saveThread(g);
                    break;
                }
              }
            });
        }
      } else {
        this.__saveThread(g);
      }
    } else {
      this.__saveThread(g);
    }
  }

  radio(event: any, priority: any): void {
    this.prioritycheck = priority;
    if (event.checked) {
      this.threadForm.patchValue({
        priority: priority,
      });
    } else {
      this.threadForm.patchValue({
        priority: null,
      });
    }
  }

  prioritySelection(prioritySelection: any): void {
    if (this.threadForm.value.priority !== prioritySelection) {
      this.threadForm.patchValue({
        priority: prioritySelection,
      });
    } else {
      this.threadForm.patchValue({
        priority: "",
      });
    }
  }

  uploadSelection(uploadStatus: string): void {
    this.threadForm.patchValue({
      uploadStatus: uploadStatus,
    });
  }

  confirmationStatus(event: any): void {
    this.confirmation = !this.confirmation;

    this.local.set("displayThreadConfirmation", { status: this.confirmation });
  }

  async __saveThread(g): Promise<void> {
    if (this.threadForm.valid) {
      // if (this.threadForm.dirty) {
      // Copy over all of the original product properties
      // Then copy over the values from the form
      // This ensures values not on the form, such as the Id, are retained

      if (this.newThread) {
        this.loadingProgress = true;
        if (g.groupId === 1) {
          g.priority = null;
        }
        const userData = {
          doc: {
            clinic_id: g.groupId,
            clinic_category_id: g.categoryId,
            doc_type: "thread",
            priority: g.priority,
            title: g.title,
            description: g.description,
            files: g.files,
            clinicCategory: this.currentSelectedCategory,
          },
        };

        // @ts-ignore
        this.threadEntity.add(userData).subscribe(
          (data) => {
            this.toast.success("", "Thread Created");
            this.loadingProgress = false;
            let threads: any = [];
            let first = false;
            this.threadEntity.entities$.subscribe((res) => {
              if (!first) {
                threads = [...res];
                first = true;
              }
            });
            const moveToTop = threads.splice(-1);
            threads.unshift(moveToTop[0]);
            this.threadEntity.clearCache();
            if (this.attachmentsC.attachedCount > 0) {
              const index = threads.findIndex(
                (thread) => thread.id === data.id
              );
              if (index !== -1) {
                threads[index].isFileBeingUploaded = true;
              }
            }
            this.threadEntity.addManyToCache(threads);
            this.matDialogRef.close(["Thread Created"]);
            if (this.attachmentsC.attachedCount > 0) {
              this.toast.info("Thread attachments are uploading!");
              this.uploadFilesToServer(data.id, data).then((res) => {
                this.saveButton = false;
                this.matDialogRef.close(["Thread Created"]);
              });
            } else {
              this.matDialogRef.close(["Thread Created"]);
            }
          },
          (error) => {
            this.toast.error(error.errors.join(", "), "Error");
            this.loadingProgress = false;
          }
        );
      } else {
        this.loadingProgress = true;
        for (const removedFile of this.removedFiles) {
          await this.threadDataService
            .deleteFile(removedFile.signed_id)
            .toPromise();
        }
        const userData = {
          doc: {
            clinic_id: g.groupId,
            clinic_category_id: g.categoryId,
            doc_type: "thread",
            priority: g.priority,
            title: g.title,
            description: g.description,
          },
        };
        this.threadDataService
          .updateThread(userData, this.threadData.id)
          .subscribe(
            (data) => {
              this.toast.success("", "Thread Updated");
              this.loadingProgress = false;
              this.threadEntity.updateOneInCache({ ...data, id: data.id });
              this.matDialogRef.close(["Thread Updated"]);
              if (this.attachmentsC.attachedCount > 0) {
                this.toast.info("Thread attachments are uploading!");
                this.uploadFilesToServer(data.id, data).then((res) => {
                  this.matDialogRef.close(["Thread Updated"]);
                });
              } else {
                this.matDialogRef.close(["Thread Updated"]);
              }
            },
            (error) => {
              this.toast.error(error.errors.join(", "), "Error");
              this.loadingProgress = false;
            }
          );
      }
    }
  }

  openFolderCreationModal(): void {
    this._matDialog
      .open(UpdateCategoryDialogComponent, {
        panelClass: "update-category-dialog",
        data: {
          currentClinic: this.currentClinic,
          create: true,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res && res[1] === true) {
          this.userClinicWithCategories();
        }
        if (res && res?.id) {
          this.currentSelectedGroup.clinic_categories.push({
            id: res.id,
            is_default: false,
            name: res.categoryName,
          });
        }
      });
  }

  groupSelection(event: any, index: any): void {
    this.currentSelectedGroup = this.groupsList[index];
    this.allowLargeFiles = this.groupsList[index]?.allow_large_files;
    if (this.attachmentsC?.attachments) {
      this.attachmentsC.attachments = [];
    }
  }
}
