import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import { Observable, of, Subject } from 'rxjs';
import { TimelineService } from './timeline.service';
import { ThreadDialogComponent } from '../dialogs/thread/thread.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ThreadsEntityService } from '../../../../store/thread/threads-entity.service';
import { Thread } from '../../../../store/thread/thread.model';
import { GroupsDataService } from '../../../../store/group/groups-data.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { GroupDialogComponent } from '../dialogs/group/group.component';
import { takeUntil } from 'rxjs/operators';
import { NewAuthDataService } from '../../../../service/api/newAuth-data.service';
import { User } from '../../../../types';

@Component({
    selector     : 'timeline',
    templateUrl  : './timeline.component.html',
    styleUrls    : ['./timeline.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class TimelineComponent implements OnInit, OnDestroy
{
    timeline: any;
    threadsData: any[];
    loadCounter = 0;
    allGroupData: any[];
    currentUser: User;
    allGroups$: Observable<any>;
    carouselFeatures = [{title: 'NAV.NEWGROUP.ADDGROUP', icon: 'add'}, {title: 'NAV.NEWGROUP.FINDGROUP', icon: 'search'}];

    // Private
    private _unsubscribeAll: Subject<any>;
    threads$: Observable<Thread[]>;

    /**
     * Constructor
     *
     * @param _timelineService
     * @param store
     * @param _authService
     * @param _matDialog
     * @param route
     * @param threadEntity
     */
    constructor(
        private _timelineService: TimelineService,
        private _matDialog: MatDialog,
        private route: ActivatedRoute,
        private threadEntity: ThreadsEntityService,
        private userService: NewAuthDataService,
        private groupDataService: GroupsDataService,
        private progressBarService: FuseProgressBarService,
        private toast: ToastrService,
    )
    {

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // this.threads = [];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.threads$ = this.threadEntity.entities$;

        this.gettingGroups();
        this.getCurrentUser();
    }

    gettingGroups(): void
    {
        this.progressBarService.show();
        this.groupDataService.getVisitedGroupList().subscribe((data) => {
            this.allGroupData = data.clinics;
            for (const feature of this.carouselFeatures)
            {
                this.allGroupData.push(feature);
            }
            this.allGroups$ = of(this.MoveMyDocsToFront(this.allGroupData));
            this.progressBarService.hide();
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.error, 'Error');
        });
    }


    MoveMyDocsToFront(groups:any[])
    {
        const myDocsIndex = groups.findIndex(group => group.name === 'My Docs');
        if (myDocsIndex !== -1) {
          groups.unshift(groups.splice(myDocsIndex, 1)[0]);
        }
        return groups;
    }


    getCurrentUser(): any{
        this.userService.getUser().pipe(takeUntil(this._unsubscribeAll)).subscribe(async (user) => {
            this.currentUser = user;
        });
    }
    private loadData(data: any[]): void
    {
        this.threadsData = [...data];
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    openThreadCreationDialog(): void
    {

        this.loadCounter = this.loadCounter + 1;
        this._matDialog.open(ThreadDialogComponent, {
            panelClass: 'card-dialog',
            data      : {
                new    : true,
                counter: this.loadCounter,
            }
        });
    }
    openGroupDialog(): void{
            this._matDialog.open(GroupDialogComponent, {
                panelClass: 'group-card-dialog',
            });
    }
    onScrollDown(): any
    {

    }
}
