import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { LocalStorage, LocalStorageService } from 'angular-web-storage';
import { NewAuthDataService } from '../../../service/api/newAuth-data.service';

@Component({
    selector     : 'vertical-layout-1',
    templateUrl  : './layout-1.component.html',
    styleUrls    : ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    @LocalStorage('app_version')
    appVersion;
    isLatest = true;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param _fuseSidebarService
     * @param local
     * @param authDataService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private local: LocalStorageService,
        private authDataService: NewAuthDataService
    )
    {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
                if ( !this.fuseConfig.layout.toolbar.hidden )
                {
                    this.authDataService.getAppVersion().subscribe((data) => {
                        this.local.set('app_version', data.app_version.web_version);
                        this.isLatest = this.appVersion === data.app_version.web_version;
                    });
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Fold the temporarily unfolded sidebar back
     */
    closeChat(): void
    {
        this._fuseSidebarService.resetChat(true);
        this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
    }
    refreshPage(): void
    {
        window.location.reload(true);
    }
}
