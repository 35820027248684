import { map, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { HttpClient } from "@angular/common/http";
import {
  GroupAdapter,
  GroupCategoryAdapter,
  GroupFilesAdapter,
  GroupObjAdapter,
  IGroup,
} from "./group.model";
import { LocalStorageService } from "angular-web-storage";
import { RestService } from "../../service/api/rest.service";
import { Update } from "@ngrx/entity";
import { User } from "../../types";
import { NewAuthDataService } from "../../service/api/newAuth-data.service";
import { GroupOpening, APIGroupOpeningHours } from "app/types/Group";
import { environment } from "environments/environment";


@Injectable({
  providedIn: "root",
})
export class GroupsDataService extends DefaultDataService<IGroup> {
  userSubject = new ReplaySubject<User>();
  private _baseClinicUsers = "clinic_users";
  private _baseCategory = "clinic_categories";
  private _baseClinic = "clinics";
  private _baseClinicThread = "docs";
  private _baseNotifications = "doc_notifications";
  private _baseClinicModules = "clinic_modules";
  private _baseClinicContacts = "clinic_contacts";
  private _baseClinicPatientRoom = "clinic_patient_rooms";
  private baseClinicPatientBoard = "patient_boards";
  private baseClinicPatientBoardSettings = "patient_board_settings";
  private baseClinicSlideShowSettings = "slide_show_albums";
  private baseSlides = "slides";
  private groupsList;
  private visibility;
  private user = new BehaviorSubject<string>("");
  castUser = this.user.asObservable();
  private baseResetNotificationsCount = "reset_notifications_count";
  private inGroupsPage = new Subject<any>();
  private shareThreadId = new Subject<any>();
  updateRoom = new Subject<any>();
  updateCategory = new Subject<any>();
  sliderSlideShowCount = new Subject<any>();
  onContactChanged: BehaviorSubject<any>;
  onContactSelected = new Subject<any>();
  scrollToBottom = new Subject<any>();
  onFilesChanged: BehaviorSubject<any>;
  onFileSelected: BehaviorSubject<any>;
  public openProfileSettings = new Subject<any>();

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private rest: RestService,
    private local: LocalStorageService,
    private adapter: GroupAdapter,
    private groupAdapter: GroupObjAdapter,
    private authDataService: NewAuthDataService,
    private groupFileAdapter: GroupFilesAdapter
  ) {
    super("Group", http, httpUrlGenerator);
    if (this.authDataService.getSignedInUser()) {
      this.userSubject.next(this.authDataService.getSignedInUser());
    }
    this.onContactChanged = new BehaviorSubject({});
    this.onFilesChanged = new BehaviorSubject({});
    this.onFileSelected = new BehaviorSubject({});
  }

  getOpenProfileSettings(): Observable<any> {
    return this.openProfileSettings.asObservable();
  }

  getScrollToBottom(): Observable<any> {
    return this.scrollToBottom.asObservable();
  }

  capitalizeFirstLetter(name): any {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  sliderSlideCount(data: any): any {
    return this.sliderSlideShowCount.next(data);
  }

  getShareThreadId(): Observable<any> {
    return this.shareThreadId.asObservable();
  }

  setShareThreadId(id: any): void {
    this.shareThreadId.next({ threadId: id });
  }

  getInGroupsPageSubject(): Subject<any> {
    return this.inGroupsPage;
  }

  createGroupBackUp(id: string | number): Observable<any> {
    return this.rest.post(`${this._baseClinic}/${id}/backup`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  verifyBackUpPassword(id: string | number, params: any): Observable<any> {
    return this.rest
      .post(`${this._baseClinic}/${id}/verify_password`, params)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  downloadBackUp(id: string | number): Observable<any> {
    return this.rest.get(`${this._baseClinic}/${id}/download_backup`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  deleteBackUp(id: string | number): Observable<any> {
    return this.rest.delete(`${this._baseClinic}/${id}/delete_backup`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  getInGroupsPage(): Observable<any> {
    return this.inGroupsPage.asObservable();
  }

  getGroupListing(params): Observable<any> {
    params.limit = 25;
    return this.rest
      .get(`${this._baseClinic}/get_all_clinic_listing_web`, params)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getVisitedGroupList(): Observable<any> {
    return this.rest
      .get(`${this._baseClinic}/visited_clinic_listing`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  add(group: Partial<IGroup>): Observable<IGroup> {
    let data = this.adapter.encode(group);
    data = {
      clinic: data,
    };
    // @ts-ignore
    data.clinic.file = group.signed_id;

    return this.rest.post(`${this._baseClinic}`, data).pipe(
      map((res) => {
        res = this.adapter.adapt(res.clinic);
        return { ...res, id: res.id };
      })
    );
  }

  update(group: Update<IGroup>): Observable<IGroup> {
    let param;
    if (group.changes) {
      param = this.adapter.encode(group.changes);
      param = {
        clinic: param,
      };
    } else {
      // @ts-ignore
      param = this.adapter.encode(group);
      param = {
        clinic: param,
      };
    }

    return this.rest.put(`${this._baseClinic}/${group.id}`, param).pipe(
      tap((data) => {
        return { ...group.changes };
      })
    );
  }

  getById(id: number): any {
    return this.rest
      .get(`${this._baseClinic}/${id.toString()}`, { hide_content: true })
      .pipe(
        map((data) => {
          const temp = this.adapter.adapt(data.clinic);
          return { ...temp, id: temp.id };
        })
      );
  }

  async getGroupObjById(id: number | any, email: string): Promise<any> {
    const data = this.rest
      .get(`${this._baseClinic}/${id.toString()}/get_group`, { email: email })
      .pipe(
        map((res) => {
          const temp = this.groupAdapter.adapt(res.group);
          return temp;
        })
      )
      .toPromise();
    return data;
  }

  getGroupUsers(id: string): any {
    return this.rest.get(`${this._baseClinic}/${id}/clinic_users`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  updateGroupVisibility(param, id): any {
    return this.rest
      .put(`${this._baseClinic}/update_clinic_visibility/${id}`, param)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  updateGroupMembersApprove(param, id): any {
    return this.rest
      .put(`${this._baseClinic}/update_clinic_approve_member/${id}`, param)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  updateGroupProfile(param, id): any {
    return this.rest.put(`${this._baseClinic}/${id}`, param).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  updateFilterUsers(param, id): any {
    return this.rest
      .put(`${this._baseClinic}/${id}/update_filter_users`, param)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  updateGroupCategory(param, id): any {
    return this.rest.put(`${this._baseCategory}/${id}`, param).pipe(
      map((data) => {
        this.updateCategory.next();
        return GroupCategoryAdapter.adapt(data.clinic_category);
      })
    );
  }

  removeGroupCategory(id): any {
    return this.rest.delete(`${this._baseCategory}/${id}`).pipe(
      tap((data) => {
        this.updateCategory.next();
        return data;
      })
    );
  }

  bindContactWithCategory(contatcId, body): Observable<any> {
    return this.rest
      .post(`clinic_contacts/${contatcId}/bind_categories`, body)
      .pipe(
        tap(() => {
          this.updateCategory.next();
        })
      );
  }

  createGroupCategory(param): any {
    return this.rest.post(`${this._baseCategory}`, param).pipe(
      map((data) => {
        this.updateCategory.next(data);
        return GroupCategoryAdapter.adapt(data.clinic_category);
      })
    );
  }

  updateGroupModules(param, id): any {
    return this.rest.put(`${this._baseClinicModules}/${id}`, param).pipe(
      map((data) => {
        return data;
      })
    );
  }

  toggleGroupRotatingQR(id: number): any {
    return this.rest.get(`${this._baseClinic}/${id}/toggle_rotating_qr`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  deleteGroup(id): any {
    return this.rest.delete(`${this._baseClinic}/${id}`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  leaveGroup(params): any {
    return this.rest.post(`${this._baseClinic}/leave_clinic`, params).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  togglePin(param): any {
    param = {
      clinic_id: param,
    };
    return this.rest
      .put(`${this._baseClinicUsers}/pin_clinic`, param)
      .pipe(tap((data) => data));
  }

  toggleShowOnDashboard(param): any {
    param = {
      clinic_id: param,
    };
    return this.rest
      .put(`${this._baseClinicUsers}/toggle_display_timeline/`, param)
      .pipe(tap((data) => data));
  }

  joinGroup(groupId): any {
    const param = {
      clinic_users: {
        clinic_id: groupId,
      },
    };
    return this.rest
      .post(`${this._baseClinicUsers}/join_clinic`, param)
      .pipe(tap((data) => data));
  }

  groupNotificationOn(params): any {
    return this.rest
      .put(`${this._baseNotifications}/group_notification_status_on`, params)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  groupNotificationOff(params): any {
    return this.rest
      .put(`${this._baseNotifications}/group_notification_status_off`, params)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  becomeAnAdmin(params): any {
    return this.rest.post(`${this._baseClinic}/become_admin`, params).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  sendGroupEmail(params): any {
    return this.rest.post(`${this._baseClinic}/send_group_email`, params).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  createGroupContact(params): any {
    return this.rest.post(`${this._baseClinicContacts}`, params).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  getUserContacts(params?: any): any {
    return this.rest.get(`${this._baseClinicContacts}/showv2`, params).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  getAllGroups(params): any {
    return this.rest.get(`${this._baseClinic}/all_system_groups`, params);
  }

  getGroupContacts(id): any {
    return this.rest.get(`${this._baseClinicContacts}/${id}`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  updateGroupContact(id, params): any {
    return this.rest.put(`${this._baseClinicContacts}/${id}`, params).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  deleteGroupContact(id): any {
    return this.rest.delete(`${this._baseClinicContacts}/${id}`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  getGroupPatientRooms(id): any {
    return this.rest.get(`${this._baseClinicPatientRoom}/${id}`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  createGroupPatientRoom(params): any {
    return this.rest.post(`${this._baseClinicPatientRoom}`, params).pipe(
      tap((data) => {
        this.updateRoom.next();
        return data;
      })
    );
  }

  updateGroupPatientRoom(id, params): any {
    return this.rest.put(`${this._baseClinicPatientRoom}/${id}`, params).pipe(
      tap((data) => {
        this.updateRoom.next();
        return data;
      })
    );
  }

  deleteGroupPatientRoom(id): any {
    return this.rest.delete(`${this._baseClinicPatientRoom}/${id}`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  cancelGroupRequest(groupId): any {
    const params = {
      clinic_users: {
        clinic_id: groupId,
        user_id: this.authDataService.getSignedInUser().id,
        request_status: "cancelled",
      },
    };
    return this.rest
      .put(`${this._baseClinicUsers}/update_clinic_user_status`, params)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  getUserGroups(): Observable<any> {
    return this.rest
      .get(`${this._baseClinic}/user_clinics_with_categories`)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  inviteUsersToGroups(params): any {
    return this.rest
      .post(`${this._baseClinicUsers}/invite_user_to_clinic`, params)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  getGroupPatientCallers(id): any {
    return this.rest
      .get(`${this.baseClinicPatientBoard}/${id}`, {
        filter: new Date(),
      })
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  createGroupPatientCaller(params): any {
    return this.rest.post(`${this.baseClinicPatientBoard}`, params).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  updateGroupPatientCaller(params, id): any {
    return this.rest.put(`${this.baseClinicPatientBoard}/${id}`, params).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  deleteGroupPatientCaller(id): any {
    return this.rest.delete(`${this.baseClinicPatientBoard}/${id}`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  createPatientCallerSettings(params): any {
    return this.rest
      .post(`${this.baseClinicPatientBoardSettings}`, params)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  getPatientCallerSettings(id): any {
    return this.rest.get(`${this.baseClinicPatientBoardSettings}/${id}`).pipe(
      tap((data) => {
        return data;
      })
    );
  }

  updatePatientCallerSetting(params, id): any {
    return this.rest
      .put(`${this.baseClinicPatientBoardSettings}/${id}`, params)
      .pipe(
        tap((data) => {
          return data;
        })
      );
  }

  getClinicSearch(params): any {
    params.search = params.search?.trim();
    return this.rest.post(`${this._baseClinic}/search`, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getClinicSearchCount(params): Observable<any> {
    return this.rest.post(`${this._baseClinic}/search_count`, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getOrCreateSlideShowSettings(groupId): any {
    const params = {
      slide_album: {
        clinic_id: groupId,
      },
    };
    return this.rest.post(`${this.baseClinicSlideShowSettings}`, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateSlideShowSettings(params, id): any {
    return this.rest
      .put(`${this.baseClinicSlideShowSettings}/${id}`, params)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getSlideShowAlbums(id): any {
    return this.rest.get(`${this.baseSlides}/${id}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getSlideGalleries(): Observable<any> {
    return this.rest.get(`slide_galleries`);
  }

  createSlide(params): any {
    return this.rest.post(`${this.baseSlides}`, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateSlide(params, id): any {
    return this.rest.put(`${this.baseSlides}/${id}`, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  deleteSlide(id): any {
    return this.rest.delete(`${this.baseSlides}/${id}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  resetDocNotificationCount(id): any {
    return this.rest.get(
      `${this._baseClinic}/${this.baseResetNotificationsCount}/${id}`
    );
  }

  getClinicSlides(clinicID): Observable<any> {
    return this.rest.get(`${this._baseClinic}/${clinicID}/slides`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getCategories(clinicID, param = {}): Observable<any> {
    return this.rest
      .get(`${this._baseClinic}/${clinicID}/clinic_categories`, param)
      .pipe(
        map((res) => {
          return res.clinic_categories.map((u) =>
            GroupCategoryAdapter.adapt(u)
          );
        })
      );
  }

  getFilesBy(id, params = {}): any {
    return this.rest.get(`${this._baseCategory}/${id}/files`, params).pipe(
      map((res) => {
        return res.files.map((u) => this.groupFileAdapter.adapt(u));
      })
    );
  }

  getStoragePlans(): any {
    return this.rest.get(`products`);
  }

  getPaymentMethods(clinicID): any {
    return this.rest.get(`${this._baseClinic}/${clinicID}/payment_methods`);
  }

  removePaymentMethod(clinicID, methodId): any {
    return this.rest.delete(
      `${this._baseClinic}/${clinicID}/payment_methods/${methodId}`
    );
  }

  createPaymentMethod(clinicID, params): any {
    return this.rest.post(
      `${this._baseClinic}/${clinicID}/payment_methods`,
      params
    );
  }

  createPaymentSubscription(clinicID, params): any {
    return this.rest.post(
      `${this._baseClinic}/${clinicID}/subscriptions`,
      params
    );
  }

  getPaymentInvoices(clinicID): any {
    return this.rest.get(
      `${this._baseClinic}/${clinicID}/subscription_invoices`
    );
  }

  getInvoiceInformation(subscriptionId:any,clinicId):any{
    return this.rest.get(`${this._baseClinic}/${clinicId}/subscriptions/${subscriptionId}/latest_invoice.json`)
  }

  getClientSecretKey(paymentIntentId:any):any
  {
    return this.http.get(`https://api.stripe.com/v1/payment_intents/${paymentIntentId}`,{
      headers:{
        'Authorization': `Bearer ${environment.stripe?.STRIPE_SECRET_KEY}`
      }
    })
  }

  getClinicUsage(clinicID): any {
    return this.rest.get(`${this._baseClinic}/${clinicID}/usage`);
  }

  makePrimaryPaymentMethod(
    clinicID: number,
    methodId: number,
    params: any
  ): any {
    return this.rest.put(
      `${this._baseClinic}/${clinicID}/payment_methods/${methodId}`,
      params
    );
  }

  cancelSubscription(
    clinicID: number,
    subscriptionId: number,
    deleteImmediatley?: any
  ): any {
    return this.rest.delete(
      `${this._baseClinic}/${clinicID}/subscriptions/${subscriptionId}`,
      deleteImmediatley
    );
  }

  getSubscriptionInvoice(clinicID: number, subscriptionId: number): any {
    return this.rest.get(
      `${this._baseClinic}/${clinicID}/subscription_invoices/${subscriptionId}`
    );
  }

  getSubscriptionInvoiceCSV(clinicID: number, subscriptionId: number): any {
    return this.rest.get(
      `${this._baseClinic}/${clinicID}/subscription_invoices/${subscriptionId}.csv`,
      {},
      { responseType: "blob" }
    );
  }

  shareThread(params): any {
    return this.rest.post(`${this._baseClinicThread}/share_thread`, params);
  }

  shareContact(params): any {
    return this.rest.post(`${this._baseClinicContacts}/share_contacts`, params);
  }

  getClinicDiscountCode(id: number): any {
    return this.rest.get(
      `${this._baseClinic}/${id}/list_clinic_registered_discount_codes`
    );
  }

  addClinicDiscountCode(id: number, params): any {
    return this.rest.post(`${this._baseClinic}/${id}/add_discount_code`, {
      discount_code: params,
    });
  }

  removeClinicDiscountCode(id, params): any {
    return this.rest.delete(
      `${this._baseClinic}/${id}/delete_clinic_discount_code`,
      { discount_code: params }
    );
  }

  getOpeningHours(
    id: number
  ): Observable<{ clinic_opening_hours: APIGroupOpeningHours[] }> {
    return this.rest.get(`${this._baseClinic}/${id}/clinic_opening_hours`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  setOpeningHours(
    data: { clinic_opening_hours: GroupOpening,time_zone:string },
    id: number
  ): Observable<{ clinic_opening_hours: GroupOpening,time_zone:string }> {
    return this.rest
      .post(`${this._baseClinic}/${id}/clinic_opening_hours`, data)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
