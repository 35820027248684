import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { ThreadsDataService } from '../../../../store/thread/threads-data.service';
import { ThreadsEntityService } from '../../../../store/thread/threads-entity.service';
import { NewAuthDataService } from '../../../../service/api/newAuth-data.service';
import { ActionCableService } from '../../../../service/socket/action-cable.service';
import { Subject, Subscription } from 'rxjs';
import { Thread, ThreadCommentAdapter } from '../../../../store/thread/thread.model';
import { FriendDataService } from '../../../../store/friend/friend-data.service';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentsComponent } from '../attachments/attachments.component';
import { fuseAnimations } from '@fuse/animations';
import { TasksEntityService } from '../../../../store/task/task-entity.service';
import { User } from '../../../../types';
import * as moment from 'moment';
import { IDocRead, ReadType } from '../../../../store/doc-read/doc-read.model';
import { DocReadEntityService } from '../../../../store/doc-read/doc-read-entity.service';
import { IGroup } from '../../../../store/group/group.model';
import { GroupsEntityService } from '../../../../store/group/groups-entity.service';

@Component({
    selector     : 'post-comments',
    templateUrl  : './comments.component.html',
    styleUrls    : ['./comments.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class CommentsComponent implements OnInit, OnDestroy
{
    @Input() post: Thread | any;
    @Input() forTask;
    postCommentForm: FormGroup;
    currentUser: User;
    editCommentIndex = -1;
    commentPage = 1;
    @ViewChild('editSpan') editSpan: any;
    private notificationsUpdate: Subscription;
    @ViewChild('attachmentsC', {static: false}) attachmentsC: AttachmentsComponent;
    loadingProgress: boolean;
    threadEntity: any;
    sub = new Subject();
    loginUser: User;
    lastAttachment: any;
    readType: typeof ReadType = ReadType;
    currentClinic: IGroup | undefined;
    allowLargeFiles: boolean;

    constructor(private _formBuilder: FormBuilder,
                private threadDataService: ThreadsDataService,
                private progressBarService: FuseProgressBarService,
                private toast: ToastrService,
                private threadEntityy: ThreadsEntityService,
                private taskEntityService: TasksEntityService,
                private authService: NewAuthDataService,
                private _matDialog: MatDialog,
                private groupEntity: GroupsEntityService,
                private friendDataService: FriendDataService,
                private actionCableService: ActionCableService,
                private threadCommentAdapter: ThreadCommentAdapter,
                private authDataService: NewAuthDataService,
                private docReadEntityService: DocReadEntityService
    )
    {
        if ( this.forTask )
        {
            this.threadEntity = taskEntityService;
        } else
        {
            this.threadEntity = threadEntityy;
        }
    }

    ngOnInit(): void
    {
        this.authDataService.getUser().subscribe((user: any) => {
            this.loginUser = user;
        });
        this.groupEntity.entities$.subscribe((obj => {
            this.currentClinic = obj.find(o => +o.id === +this.post?.clinicId);
            this.allowLargeFiles = this.currentClinic?.clinicModules?.large_file_size ?? false;
        }));
        if ( this.post.docComments )
        {
            const docReadList: IDocRead[] = [];
            for (const comment of this.post?.docComments)
            {
                if ( comment?.commentReads )
                {
                    for (const commentRead of comment?.commentReads)
                    {
                        const tempRead: IDocRead = {
                            id    : commentRead.id,
                            itemId: comment.id,
                            user  : commentRead.user,
                            type  : ReadType.comment
                        };
                        docReadList.push(tempRead);
                    }
                }
            }
            this.docReadEntityService.addManyToCache(docReadList);
            this.post.docComments = this.sortedComments(this.post.docComments);
        }
        this.notificationsUpdate = this.actionCableService.getClinicUpdate()
            .subscribe(({comment}) => {
                if ( comment?.doc_comment?.doc_id === this.post.id )
                {
                    const commentIndex = this.post.docComments.findIndex(res => +res.id === +comment.doc_comment.id);
                    if ( commentIndex !== -1 )
                    {
                        this.post.docComments.splice(commentIndex, 1, this.threadCommentAdapter.adapt(comment.doc_comment));
                    } else
                    {
                        this.post.docComments = this.sortedComments([this.threadCommentAdapter.adapt(comment.doc_comment), ...this.post.docComments]);
                        if ( comment.doc_comment.comment_type === 'comment' )
                        {
                            this.post.clinicDocCommentsCount = this.post.clinicDocCommentsCount + 1;
                        } else
                        {
                            this.post.clinicDocCommentsCount = this.post.clinicDocCommentsCount + 1;
                            this.post.clinicDocCommentFileCount = this.post.clinicDocCommentFileCount + comment?.doc_comment?.files?.length;
                        }
                    }
                }
                if (  this.threadDataService.viewedDocIds.includes(comment?.doc_comment?.doc_id) )
                {
                    this.threadDataService.docReadsRequests.next({doc_id: comment?.doc_comment?.doc_id});
                }
            });
        this.postCommentForm = this._formBuilder.group({
            comment: ['', Validators.required]
        });
        this.authService.getUser().subscribe((data) => {
            this.currentUser = data;
        });
    }

    deleteComment(comment): void
    {
        const tempIndex = this.post.docComments.indexOf(comment);
        this.progressBarService.show();
        this.threadDataService.deleteThreadComment(this.post.docComments[tempIndex].id).subscribe((data) => {
                this.toast.success('', data.message);
                this.progressBarService.hide();
                this.post.docComments.splice(tempIndex, 1);
                if ( comment.files?.length > 0 )
                {
                    this.post.clinicDocCommentFileCount = this.post.clinicDocCommentFileCount - comment.files.length;
                }
                this.post.clinicDocCommentsCount = this.post.clinicDocCommentsCount - 1;
                this.threadEntity.updateOneInCache({
                    clinicDocCommentsCount   : this.post.clinicDocCommentsCount,
                    clinicDocCommentFileCount: this.post.clinicDocCommentFileCount,
                    docComments              : this.post.docComments,
                    id                       : this.post.id
                });
            },
            (error) => {
                this.toast.error(error.errors.join(', '), 'Error');
                this.progressBarService.hide();
            });
    }

    editComment(comment: any): void
    {
        this.editCommentIndex = this.post.docComments.indexOf(comment);
    }

    ngOnDestroy(): void
    {
        this.notificationsUpdate?.unsubscribe();
    }

    loadMore(): void
    {
        this.loadingProgress = true;
        this.threadDataService.getThreadComments(this.post.id, {
            page : ++this.commentPage,
            limit: 5
        }).subscribe((res) => {
            this.loadingProgress = false;
            this.post.docComments = this.sortedComments([
                ...this.post.docComments,
                ...res.clinic_doc_comments.map(d => this.threadCommentAdapter.adapt(d))
            ]);
        });
    }

    sortedComments(allComments): any
    {
        return allComments.sort((a, b) => {
            return moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf();
        });
    }

    updateDone(): any
    {
        this.sub.asObservable().subscribe(() => {
            this.editCommentIndex = -1;
        });
        return this.sub;
    }

    gettingLastIndex(data): void
    {
        this.lastAttachment = data;
        this.deleteComment(this.lastAttachment);
    }
}
