import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { IUploadingFiles } from './uploading-files.model';
import { UploadingFilesEntityService } from './uploading-files-entity.service';
import { Observable, Subject, Subscription } from 'rxjs';

@Injectable()
export class UploadingFilesDataService extends DefaultDataService<IUploadingFiles>
{
    uploadingSubscription: Subscription;
    private isUploading = new Subject<any>();

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private uploadingFilesEntityService: UploadingFilesEntityService)
    {
        super('UploadingFiles', http, httpUrlGenerator);
    }

    isFileUploading(threadID): void
    {

        this.uploadingSubscription = this.uploadingFilesEntityService.entities$.subscribe((data) => {
                const found = data.find((element) => element.threadId === threadID);
                if ( found )
                {
                    this.isUploading.next({state: true, postId: threadID});
                } else
                {
                    this.isUploading.next({state: false, postId: threadID});
                }

            }

        );
    }


    checkFileBeingUploaded(): Observable<any>
    {
        return this.isUploading.asObservable();
    }

}
