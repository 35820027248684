import { NgModule } from '@angular/core';
import { MainDashboardComponent } from './main/main-dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule, FuseWidgetModule } from '@fuse/components';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TimelineModule } from '../components/timeline/timeline.module';
import { DashboardTasksComponent } from './main/dashboard-tasks/dashboard-tasks.component';
import { GroupDialogComponent } from '../components/dialogs/group/group.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GroupInviteModule } from '../components/dialogs/group-invite-modal/group-invite.module';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { DashboardResolve } from './dashboard.resolve';
import { AuthGuard } from '../../../service/auth.guard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TaskModule } from '../components/task/task.module';
import { TranslateModule } from '@ngx-translate/core';
import { UtilModule } from '../components/util/util.module';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';


const routes: Routes = [
    {
        path      : '',
        redirectTo: 'home',
        pathMatch : 'full',
        canActivate : [AuthGuard]
    },
    {
        path     : 'home',
        component: MainDashboardComponent,
        resolve  : {
            data: DashboardResolve
        },
        canActivate : [AuthGuard]
    }
];

@NgModule({
    declarations   : [MainDashboardComponent, DashboardTasksComponent],
    imports: [
        RouterModule.forChild(routes),

        // StoreModule.forRoot({ 'groups' : reducer, 'threads' : threadReducer }),
        // StoreModule.forFeature('groups', reducer),
        // StoreModule.forFeature('threads', threadReducer),

        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTabsModule,
        CommonModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseWidgetModule,
        TimelineModule,
        DragDropModule,
        MatSelectModule,
        MatBadgeModule,
        InfiniteScrollModule,
        TaskModule,
        TranslateModule,
        UtilModule,
        NgxMatIntlTelInputModule,
        GroupInviteModule
    ],
    providers      : [
        DashboardResolve
    ],
    entryComponents: [GroupDialogComponent]
})
export class DashboardModule
{
}
