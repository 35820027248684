import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "stripHtml",
})
export class StripHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(value): any {
    const html = this.sanitized.sanitize(SecurityContext.HTML, value);
    return stripHtml(html?.toString() || "");
  }
}

function stripHtml(html: string)
{
   let tmp = document?.createElement("DIV");
   if (!tmp) return "";
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}