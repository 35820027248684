import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UtilityService } from "app/service/utility.service";
import { GroupQRCode } from "app/store/group/group.model";
import { Subject } from "rxjs";

export interface DownloadQrCodeComponentDialogData {
  qrCode: GroupQRCode;
}

@Component({
  selector: "app-download-qr-code",
  templateUrl: "./download-qr-code.component.html",
  styleUrls: ["./download-qr-code.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DownloadQrCodeComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  loading = true;
  downloading = false;
  qrCode: GroupQRCode | undefined = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private dialogData: DownloadQrCodeComponentDialogData,
    private dialogRef: MatDialogRef<DownloadQrCodeComponent>,
    private util: UtilityService
  ) {}

  ngOnInit(): void {
    this.qrCode = this.dialogData.qrCode;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
  }

  download() {
    if (this.downloading || !this.qrCode?.download_url) return;
    this.downloading = true;

    this.util
      .downloadFileToUserSystem(
        this.qrCode?.download_url,
        this.qrCode?.name || "qr-code.png"
      )
      .subscribe({
        next: () => {
          this.downloading = false;
          this.dialogRef.close();
        },
        error: () => {
          this.downloading = false;
        },
      });
  }
}