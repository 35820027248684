<div id="timeline" class="p-24" fxLayout="row wrap">

    <div class="timeline-content" fxLayout="column" fxFlex="100">

        <carousel [arrowsOutside]="true">

            <div class="carousel-cell" *ngFor="let group of allGroupData">
                <div *ngIf="!group.icon" routerLink="/groups/{{group.id}}">
                    <img *ngIf="group.file !== null && group.name !== 'My Docs'" [src]="group?.file?.src" class="">
                    <img class="" src="assets/images/Group/users-group.png"
                         *ngIf="group.file == null && group.name !== 'My Docs'">
                         <img class="" src="assets/images/Group/MyDocsimg.jpg"
                         *ngIf="group.name == 'My Docs'">
                    <div class="bottom-left">
                        <div class="groupInfo">
                            <div><p>{{group.name}}</p></div>
                            <div *ngFor="let admin of group?.clinic_admins">
                                <mat-icon *ngIf="admin.id === currentUser.id">font_download</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carouselFeature carousel-cell" *ngIf="group.icon === 'search' "
                     [routerLink]="['/groups/all']">
                    <mat-icon class="mt-40">{{group.icon}}</mat-icon>
                    <p>{{group.title | translate}}</p>
                </div>
                <div class="carouselFeature carousel-cell" (click)="openGroupDialog()" *ngIf="group.icon === 'add' ">
                    <mat-icon class="mt-40">{{group.icon}}</mat-icon>
                    <p>{{group.title | translate}}</p>
                </div>
            </div>
        </carousel>
        <mat-divider></mat-divider>
    </div>
</div>
<div class="container" id="post1" fxLayout="column" fxFlex="100">
    <ng-container *ngIf="threads$ | async as threads">
        <post [@animate]="{value:'*',params:{y:'100%'}}" [post]="thread" *ngFor="let thread of threads"></post>
    </ng-container>
</div>
