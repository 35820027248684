import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { TaskComponent } from "../../dialogs/task/task.component";
import { TaskDataService } from "../../../../../store/task/task-data.service";
import { MatDialog } from "@angular/material/dialog";
import { TasksEntityService } from "../../../../../store/task/task-entity.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "scrumboard-board-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ScrumboardBoardCardComponent implements OnInit {
  @Input()
  card;
  board: any;
  @Input() module: 'planning' | 'time_tracker' = 'planning';

  constructor(
    private taskDataService: TaskDataService,
    private taskEntity: TasksEntityService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private _matDialog: MatDialog,
    private toastrService: ToastrService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.taskEntity.entities$.subscribe((dataList) => {
      if (dataList.length) {
        this.sortedTasks(dataList);
        this.board = dataList;
      }
    });
  }

  sortedTasks(allTasks): any {
    return allTasks.sort((a, b) => {
      return this.getStatus(a.taskStatus) === this.getStatus(b.taskStatus)
        ? moment(a.taskStart).isAfter(moment(b.taskStart) ? 1 : -1)
        : this.getStatus(a.taskStatus) > this.getStatus(b.taskStatus)
        ? 1
        : -1;
    });
  }

  getStatus(taskStatus): any {
    switch (taskStatus) {
      case "in_progress":
        taskStatus = 1;
        break;
      case "to_do":
        taskStatus = 2;
        break;
      case "done":
        taskStatus = 3;
        break;
      case "archived":
        taskStatus = 4;
        break;
    }
    return taskStatus;
  }

  openCardDialog(task): void {
    this.taskDataService.getTaskByID(task.id).subscribe((data) => {
      this._matDialog.open(TaskComponent, {
        panelClass: "task-card-dialog",
        data: {
          type: this.router.url.includes("task_tracker") ? "plan" : "task",
          new: false,
          taskData: data,
          module: this.module,
        },
      });
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Is the card overdue?
   *
   * @param cardDate
   * @returns {boolean}
   */
  isOverdue(cardDate): boolean {
    return moment() > moment(new Date(cardDate));
  }

  getCardDescription(value): any {
    const extractedDescription = value
      .replace(/(<([^>]+)>)/gi, "")
      .slice(0, 250);
    return extractedDescription;
  }
}
