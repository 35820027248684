import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileDirective } from './user-profile.directive';
import { ClickOutsideChatDirective } from './click-outside-chat.directive';
import { ObserveVisibilityDirective } from './visibility.directive';
import { ButtonClickDebounceDirective } from './button-click-debounce.directive';


@NgModule({
    declarations: [UserProfileDirective, ClickOutsideChatDirective, ObserveVisibilityDirective, ButtonClickDebounceDirective],
    exports: [UserProfileDirective, ClickOutsideChatDirective, ObserveVisibilityDirective, ButtonClickDebounceDirective],
    imports     : [
        CommonModule
    ],
})
export class CommonDirectiveModule
{
}
