import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule} from '@ngx-translate/core';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { AdminPanelPasswordDialogComponent } from './admin-panel-password-dialog.component';


@NgModule({
  declarations: [AdminPanelPasswordDialogComponent],
  imports: [
    CommonModule,
      FuseSharedModule,
      MatToolbarModule,
      MatIconModule,
      MatButtonModule,
      MatDialogModule,
      MatProgressBarModule,
      MatFormFieldModule,
      MatInputModule,
      NgxMatIntlTelInputModule,
      TranslateModule,
  ]
})
export class AdminPanelPasswordDialogModule { }
