import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { GroupUser, IGroup } from '../group.model';
import { RestService } from '../../../service/api/rest.service';
import { ReplaySubject, merge, of } from 'rxjs';
import { User } from 'app/types';
import { GroupsEntityService } from '../groups-entity.service';
import { SystemUsersEntityService } from 'app/store/system-users/system-user-entity.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class GroupUsersEntityService extends EntityCollectionServiceBase<GroupUser> {
  private currentUser: User | undefined = undefined;
  private currentGroup: IGroup | undefined = undefined;
  private _isAdmin = false;
  public readonly isAdmin$ = new ReplaySubject<boolean>();

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private rest: RestService,
    private userEntity: SystemUsersEntityService,
    private groupEntity: GroupsEntityService
  ) {
    super("GroupUser", serviceElementsFactory);

    merge(
      of({ key: "currentUser", value: this.userEntity.currentUser }),
      of({ key: "currentGroup", value: this.groupEntity.currentGroup }),

      this.userEntity.currentUser$.pipe(
        map((v) => ({ key: "currentUser", value: v }))
      ),

      this.groupEntity.currentGroup$.pipe(
        map((v) => ({ key: "currentGroup", value: v }))
      )
    )
      .pipe(switchMap((v) => of(v)))
      .subscribe({
        next: (data) => {
          this[data.key] = data.value;

          if (this.currentGroup && this.currentUser) {
            this._isAdmin = !!this.currentGroup?.clinicAdmins?.find(
              (i) => i.id === this.currentUser?.id
            );

            // so that we don't trigger unnecessary
            this.isAdmin$.next(this._isAdmin);
          }
        },
      });
  }

  get isAdmin() {
    return this._isAdmin;
  }

  ensureAdmin() {
    if (!this.isAdmin) {
      // TODO: Add Toast to let user know he can't perform such an operation
      // THROW ? YES : we need to throw to break the code flow
      throw new Error("Forbidden");
    }
  }
}
