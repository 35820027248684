import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorage, LocalStorageService, SessionStorage } from 'angular-web-storage';
import { Blob, DirectUpload } from 'activestorage';
import { UserIdleService } from 'angular-user-idle';
import { ToastrService } from 'ngx-toastr';
import { UploadingFilesDataService } from '../../store/uploading-files/uploading-files-data.service';
import { FileUploadService } from './file-upload.service';

@Injectable({
    providedIn: 'root'
})
export class RestService
{
    private BASE_URL = environment.BASE_API_URL;
    @LocalStorage('token')
    userToken;
    @SessionStorage('token')
    sessionToken;
    private uploadProgressSubject = new Subject<any>();
    private  confirmLogout =  new BehaviorSubject<boolean>(false);
    constructor(private http: HttpClient, private local: LocalStorageService,
                private toast: ToastrService,
                private userIdle: UserIdleService,
                private  injector: Injector)
    {
    }

    public _getHeader(headerType?): HttpHeaders
    {
        if ( this.userToken || this.sessionToken )
        {
            return new HttpHeaders({
                'Accept'       : 'application/json',
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${(this.userToken || this.sessionToken)}`
            });
        }
        return new HttpHeaders({
            'Content-Type': headerType || 'application/json',
            'Accept'      : headerType || 'application/json'
        });
    }

    public setUserToken(x): void
    {
        this.userToken = x;
    }

    public get(action, params = {}, options = {}): Observable<any>
    {
        return this.http.get(`${this.BASE_URL}${action}`, {
            params,
            headers: this._getHeader(),
            ...options
        }).pipe(catchError((e) => this.authErrorCatch(e)));
    }


    public delete(action, params = {}): Observable<any>
    {
        return this.http.delete(`${this.BASE_URL}${action}`, {
            params,
            headers: this._getHeader()
        }).pipe(catchError((e) => this.authErrorCatch(e)));
    }

    public post(action, params = {}, headerType = ''): Observable<any>
    {
        return this.http.post(`${this.BASE_URL}${action}`, params, {
            headers: this._getHeader(headerType)
        }).pipe(catchError((e) => this.authErrorCatch(e)));
    }


    public postMPF(action, params = {}): Observable<any>
    {
        // const headerType = 'multipart/form-data';
        const headerType = '';
        return this.http.post(`${this.BASE_URL}${action}`, params, {
            headers: this._getHeader(headerType)
        }).pipe(catchError((e) => this.authErrorCatch(e)));
    }


    public put(action, params = {}): Observable<any>
    {
        return this.http.put(`${this.BASE_URL}${action}`, params, {
            headers: this._getHeader()
        }).pipe(catchError((e) => this.authErrorCatch(e)));
    }

    setConfirmLogout(logout): void
    {
        this.confirmLogout.next(logout);
    }

    getConifrmLogout(): Observable<any>
    {
        return this.confirmLogout.asObservable();
    }

    postDirect(file, currentFileNumber, totalFiles): Promise<Blob>
    {
        this.uploadProgressSubject.next({progress: 0, name : file.name, currentFile: currentFileNumber, totalFiles});
        this.userIdle.stopWatching();
        let url = `${this.BASE_URL}/direct_uploads`;
        const fileUpload =  this.injector.get(FileUploadService);
        if ( file.clinic_id )
        {
            url = url + `?clinic_id=${file.clinic_id}`;
        }
        return new Promise((resolve, reject) => {
            new DirectUpload(file, url, {
                directUploadWillCreateBlobWithXHR: (x) => {
                    x.setRequestHeader('Accept', 'application/json');
                    x.setRequestHeader('Content-Type', 'application/json');
                    x.setRequestHeader('Authorization', `Bearer ${(this.userToken || this.sessionToken)}`);
                },
                directUploadWillStoreFileWithXHR: (x) => {
                    x.setRequestHeader('x-ms-version', '2021-02-12');
                    x.upload.addEventListener('progress',
                        (event) => {
                            this.getConifrmLogout().subscribe((data) => {
                                if ( data )
                                {
                                    x.abort();
                                    this.uploadProgressSubject.next({progress: 100, name : file.name, currentFile : currentFileNumber, totalFiles, show: false});
                                    fileUpload.isUploadCalled = false;
                                    fileUpload.queue = [];
                                }
                                else {
                                    this.directUploadDidProgress(event, file.name, currentFileNumber, totalFiles);

                                }
                            });
                        });

                }
            }).create((error: any, blob: Blob) => {
                if ( error?.includes('422') )
                {
                    this.uploadProgressSubject.next({progress: 100, name : file.name, currentFile : currentFileNumber, totalFiles});
                    resolve(error);
                }
                this.userIdle.startWatching();
                resolve(blob);
            });
        });
    }

    getUploadProgressSubject(): Observable<any>
    {
        return this.uploadProgressSubject.asObservable();
    }

    directUploadDidProgress(event, name, currentFile, totalFiles): any
    {
        this.uploadProgressSubject.next({progress: Math.round((event.loaded / event.total) * 100), name : name, currentFile, totalFiles});
    }

    private authErrorCatch(e): Observable<never>
    {
        if ( e.status === 401 )
        {
            if ( this.endsWith(e.url, 'login.json') )
            {
                this.local.remove('token');
            } else
            {
                this.local.clear();
                window.location.reload(true);
            }
        }
        return throwError(e.error);
    }
    endsWith(str, suffix): boolean
    {
        return str.indexOf(suffix, str.length - suffix.length) !== -1;
    }
}
