<div class="loading-div comment-loading" *ngIf="loadingProgress">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>
<ng-container *ngIf="post.docComments">
    <ng-container *ngIf="(post.clinicDocCommentsCount - post.docComments.length)>0">
        <div (click)="loadMore()" class="comment-count group-name-color"
             fxLayout="row" fxLayoutAlign="start center">
            {{'post.show' | translate}} {{post.clinicDocCommentsCount - post.docComments.length}} {{'post.more_comments' | translate}}
        </div>
    </ng-container>
</ng-container>
<div class="comment" fxLayout="row"
     fxFlexFill *ngFor="let comment of post.docComments; let i = index">
    <profile-image [userProfile]="comment.userId" class="avatar cursor-pointer"
                   [src]="comment.userCommenting?.file?.thumb_url || comment.userCommenting?.comment_user_file?.thumb_url"></profile-image>

    <div fxLayout="column" fxFlex>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div [userProfile]="comment.userId">
                <span class="username cursor-pointer">{{comment.userCommenting.full_name}}</span>
                <span class="time">{{comment.createdAt | amTimeAgoCustom | async }}</span>
            </div>

            <div fxFlex class="align-right" *ngIf="!comment.isSystemGeneratedComment">
                <button *ngIf="currentUser?.id === comment.userCommenting.id || post.isAdmin" mat-icon-button
                        [matMenuTriggerFor]="commentMenu"
                        aria-label="More">
                    <mat-icon class="s-16">more_horiz</mat-icon>
                </button>
                <mat-menu #commentMenu="matMenu">
                    <button *ngIf="currentUser?.id === comment.userCommenting.id" (click)="editComment(comment)"
                            mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span>{{'DASHBOARD_TASK.edit' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="deleteComment(comment)">
                        <mat-icon>delete</mat-icon>
                        <span>{{'DASHBOARD_TASK.delete' | translate}}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="comment-thread">
            <ng-container *ngIf="i === editCommentIndex">
                <comment-reply [post]="post"
                               [editComment]="comment"
                               [allowLargeFiles]="allowLargeFiles"
                               [threadEntity]="threadEntity"
                               [updateDone]="updateDone()"
                               (filesLastCount)="gettingLastIndex($event)"></comment-reply>

            </ng-container>
            <ng-container *ngIf="i !== editCommentIndex">
                <app-gallery *ngIf="comment.commentType === 'file'" [items]="comment.files" size="small"></app-gallery>
            </ng-container>
            <div *ngIf="i !== editCommentIndex" fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="1 1 auto">
                    <app-linkify-read-more *ngIf="comment.commentType === 'comment' || comment.commentType === 'checkin' || comment.commentType === 'checkout'" [limit]="250"
                                           [content]="comment.comment"></app-linkify-read-more>
                </div>
                <comment-read [id]="comment.id" [type]="readType.comment"></comment-read>
            </div>
        </div>
    </div>
</div>



<comment-reply [allowLargeFiles]="allowLargeFiles" (filesLastCount)="gettingLastIndex($event)" [post]="post" [threadEntity]="threadEntity"></comment-reply>

