import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { fuseAnimations } from '@fuse/animations';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ThreadDialogComponent } from '../../components/dialogs/thread/thread.component';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { NewAuthDataService } from '../../../../service/api/newAuth-data.service';
import { NotificationDataService } from '../../../../store/notifications/notification-data.service';
import { ThreadsDataService } from '../../../../store/thread/threads-data.service';
import { ThreadsEntityService } from '../../../../store/thread/threads-entity.service';

import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../../types';

@Component({
    selector     : 'app-main-dashboard',
    templateUrl  : './main-dashboard.component.html',
    styleUrls    : ['./main-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class MainDashboardComponent implements OnInit, OnDestroy
{
    name: string;
    routeQueryParams$: Subscription;
    private _unsubscribeAll: Subject<any>;
    page = 1;
    selectedLanguage: any;
    holdingScroll = false;

    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _authService: NewAuthDataService,
        private store: Store<any>,
        private _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _fuseNavigationService: FuseNavigationService,
        private notificationDataService: NotificationDataService,
        private threadDataService: ThreadsDataService,
        private threadsEntityService: ThreadsEntityService,
        private _translateService: TranslateService,
        // public matDialogRef: MatDialogRef<GroupDialogComponent>
    )
    {
        this._unsubscribeAll = new Subject();
        this.routeQueryParams$ = route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(params => {

            if ( params['dialog'] === 'group' )
            {
                // this.openPopDialog();
            } else if ( params['dialog'] === 'thread' )
            {
                this.openThreadDialog();
            }
        });


    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    openThreadDialog(): void
    {
        this._matDialog.open(ThreadDialogComponent, {
            panelClass: 'card-dialog',
            data      : {
                new: true
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onScrollDown(): void
    {
        const taskData = {
            'clinic_filter': {
                'clinic_ids'  : [],
                'category_ids': [],
                'page'        : ++this.page,
                'limit'       : 3
            }
        };
        this.threadDataService.getDashboardThreads(taskData).subscribe((data) => {
            !data ? this.holdingScroll = true : this.holdingScroll = false;
            this.threadsEntityService.addManyToCache(data);
        });
    }

    ngOnInit(): void
    {
        this._authService.getUser()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.name = user.first_name;
            });

    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
}
