import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [{
            id       : 'dashboard',
            title    : 'Dashboard',
            translate: 'NAV.DASHBOARD',
            type     : 'item',
            icon     : 'dashboard',
            url      : '/home',
        }, {
            id       : 'notification',
            title    : 'Notification',
            translate: 'NAV.NOTIFICATION.TITLE',
            type     : 'item',
            icon     : 'notifications_active',
            url      : '/notifications',
        }, {
            id       : 'messages',
            title    : 'Chat',
            translate: 'NAV.MESSAGES.TITLE',
            type     : 'item',
            icon     : 'chat',
            url      : '/messages',
        }]
    },
    {
        id       : 'back_office',
        title    : 'Back Office',
        translate: 'NAV.Back-Office',
        type     : 'group',
        children : [

            {
                id       : 'user-management',
                title    : 'User Management',
                translate: 'NAV.User-Management',
                type     : 'item',
                icon     : 'person_pin',
            }, {
                id       : 'group-management',
                title    : 'Group Management',
                translate: 'NAV.Group-Management',
                type     : 'item',
                icon     : 'group',
            }, {
                id       : 'coupon-management',
                title    : 'Coupon Management',
                translate: 'NAV.Coupon-Management',
                type     : 'item',
                icon     : 'ballot',
            }
        ]
    },
    {
        id       : 'my_docs',
        title    : 'My Docs',
        translate: 'GROUPS.my_docs',
        type     : 'group',
        children : []
    },
    {
        id       : 'quick_access',
        title    : 'Quick Access',
        translate: 'NAV.Quick-Access',
        type     : 'group',
        children : []
    }
];
